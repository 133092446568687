// @flow
import React, { Component } from "react";
import { LocalStorage } from "services";

type Props = {};

type State = {
    colors: Array<string>,
    header: boolean,
    footer: boolean,
};

/**
 * Theme
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description Theme component
 */
class Theme extends Component<Props, State> {
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props);
        this.state = {
            colors: [],
            header: false,
            footer: false,
        };
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @method componentDidMount
         * @memberof Providers.ConnectionProvider
         * @return { Void }
         */
        const colors = await LocalStorage.getItem("colors");
        const header = await LocalStorage.getItem("header");
        const footer = await LocalStorage.getItem("footer");
        await this.setState({
            colors: colors.split(","),
            header: header === "false" ? false : true,
            footer: footer === "false" ? false : true,
        });
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Components.Theme
         * @return { String } JSX
         */

        return (
            <style>{`
            .page{ padding-top: ${
                     this.state.header ? "5rem" : "0"
                 } !important; }
            .bg-gradient{  
                    background-color: ${this.state.colors[0]};
                    height:  ${this.state.header ? "250px" : "150px"};
                }
            .Ccn-detail{    height:  ${
                        this.state.header ? "250px" : "200px"
                    }}
            .color-default{
                color:  ${this.state.colors[1]} !important;
            }
           .custom-fill{
                fill: ${this.state.colors[2]}  !important;;
            }
           
            .color-default-light{
                color:  ${this.state.colors[1]} !important;
            }

            .ui.loader {
                color: grey !important;
                &.loader:after{
                    border-color:  ${
                        this.state.colors[1]
                    } transparent transparent !important;
                }
                &.loader:before{
                    border-color: #CCCCCC !important
                }
            }
            .ui.checkbox input:checked ~ .box:after, .ui.checkbox input:checked ~ label:after{
                color: ${this.state.colors[1]} !important;
            }

            .btn.default, .btn.custom {
                background-color:  ${this.state.colors[1]} !important;
            }
        
            .progress .bar {
                background: ${this.state.colors[2]} !important;      
            }
        
            .Widget-block {
            .bar, .fill {
                position: absolute;
                border: 0.08em solid  ${this.state.colors[2]} !important;;
                width: 1 - (2 * 0.08em);
                height: 1 - (2 * 0.08em);
                clip: rect(0em, 0.5em, 1em, 0em);
                border-radius: 50%;
                }
            }

            .Companies{
                .link{
                    position: relative;
                    color:  ${this.state.colors[1]}!important;
                
                    i.right{
                        margin-top: -0.3em !important
                    }
                    .content{
                        color: grey !important;
                        margin-top: 0.3em !important
                    }
                }
            }

            .ChatBot{
                .rsc-custom-os-option-element {
                    box-shadow:  none !important;
                    border: none !important;
                    border-color: ${this.state.colors[1]} !important;
                    color: rgb(255, 255, 255)  !important;
                    display: inline-block !important;
                    font-size: 14px !important;
                    background: ${this.state.colors[1]} !important;
                    border-radius: 12px !important;
                    padding: 10px !important;
                    
                    
                }
            }

            .result{
                .list {
                    .header{
                        color: ${this.state.colors[1]} !important;
                        font-size: 1.2em;
                        margin-bottom: 0.5em !important;
                    }
                    .item{
                        padding: 1em 0 !important
                    }   
                }
            }

            `}</style>
        );
    }
}
export { Theme };
