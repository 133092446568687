// @flow
import React, { Component, Fragment } from 'react';
import { Loading } from 'components';
import { WidgetBlock } from 'widgets';
import { Grid, Transition, Header, Segment, Message, Button, Icon } from 'semantic-ui-react';

import './CcnDetail.scss';

type Props = {
    consumers: any,
    location: any,
    history: any,
    match: any
}

type State = {
    loading: boolean,
    visible: boolean,
    ccn: any,
    college: string,
    disposition: string
}

const paths = {
    redirect : "/recherche/ccn"
}

/**
 * CcnDetail
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description CcnDetail container
 */
class CcnDetail extends Component<Props,State> {
    
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = { 
            loading: false,
            visible: false,
            disposition: '',
            college: '',
            ccn: {}
        }
    }

    async componentDidMount(){
        /**
         * @instance
         * @async
         * @memberof Containers.CcnDetail
         * @method componentDidMount
         * @return { Void } 
        */
        if(!this.props.location.state){
            this.props.history.push(paths.redirect)
        }else{
            await this.setState({ 
                disposition: this.props.location.state.disposition,
                college: this.props.location.state.college
             });
        
            const { id } = this.props.match.params;
            const params = this.props.location.state;
            const { ccns } = this.props.consumers;
            const ccn = await ccns.getLocalCcn();

            if(!params || !ccn || ccn.ref !== id){
                this.props.history.push(`/ccn/${id}`)
            }else{
                if(ccn){
                    await this.setState({ 
                        loading: true,
                        visible: true, 
                        ccn: ccn,
                        college: params.college,
                        disposition: params.disposition
                    });
                }
            }
        }
    }

    handleSwitchClick = async(event: any) => {
        /**
         * @instance
         * @async
         * @method handleSwitchClick
         * @memberof Containers.CcnDetail
         * @description Change college
         * @return { Void } 
         */
        const college = event.target.name;
        if(college){
            await this.setState({ 
                college: college
            });
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.CcnDetail
         * @return { String } JSX 
         */
        const { loading, visible, disposition, college, ccn} = this.state
        return (
            <Fragment>
                {loading && ccn && disposition && college ? (
                   <Grid columns={1} className='Ccn-detail page bg-gradient'>
                    <Grid.Row >
                        <Grid.Column>
                            <Transition visible={visible} animation='fade up' duration={500}>
                                <Header as='h2' textAlign='center' className='lead'>
                                    IDCC {ccn.idcc} - {ccn._structure[disposition].title}
                                </Header>
                            </Transition>
                            <Segment style={{ padding: 0}} basic textAlign='center'>
                            {Object.keys(ccn.dispositions[disposition]).reverse().map((element, index)=>{
                                let color = '';
                                if(ccn.dispositions && ccn.dispositions[disposition]){
                                    if(ccn.dispositions[disposition][element]){
                                        color = 'yellow'
                                    }
                                    if(ccn.dispositions[disposition][element]['assureurs']){
                                        color = 'orange'
                                    }
                                    if(
                                        ccn._metadata && 
                                        ccn._metadata.dispositions[disposition] && 
                                        ccn._metadata.dispositions[disposition][element].unusable){
                                        color = 'red'
                                    }
                                }
                                let active = (element === college) ? true : false;
                               return (
                                    <Button 
                                        key={index}
                                        onClick={this.handleSwitchClick} 
                                        name={element} 
                                        active={active}
                                        className= {`btn custom`}
                                        size='medium'
                                    >
                                        <Icon name='circle' color={color}/>
                                        {ccn._structure[disposition]['colleges'][element]}
                                    </Button>
                                    )
                            })}
                            </Segment>
                            {ccn._widgets && ccn.dispositions ? (
                                <Fragment>
                                    {Object.keys(ccn.dispositions[disposition][college]).map((element, index) => { 
                                        const props =  {
                                            data : ccn.dispositions[disposition][college][element],
                                            widget: ccn._widgets.dispositions[disposition][college][element]
                                        }
                                        return <WidgetBlock key={index} {...props} />
                                    })}
                                </Fragment>
                            ):(
                                <Segment textAlign='center'>
                                    <Message>
                                        <Message.Header content={`CCN ${ccn.idcc} est en cours de mise à jour.`}/>
                                        <Message.Content content='Merci de reconsulter plus tard'/>
                                    </Message>
                                </Segment>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                ) : (
                    <Loading title='Chargement'/>
                )}
            </Fragment>
        )
    }
}

export { CcnDetail }