// @flow
import { LocalStorage } from './storageService';
import { checkStatus, checkError } from 'tools';

/**
 * Knowledge service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description Knowledge service
 */
class Knowledge {

    static entrypoints:any = {
        getKnowledge : 'apps/ccn/knowledge'
    }
    
    static url: any = process.env.REACT_APP_API_URL;

    static getKnowledge = async (e:string, a: string, s:string) => {
        /**
         * Get knowledge
         * @async 
         * @method getKnowledge
         * @memberof Services.Knowledge
         * @param q Query parameters
         * @return { Object } Response knowledge
         */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.getKnowledge}?e=${e}&a=${a}&s=${s}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
      }
    }

    static getKnowledgeBySiret = async (sirets:any) => {
        /**
         * Get knowledge by siret
         * @async 
         * @method getKnowledgeBySiret
         * @memberof Services.Knowledge
         * @param siret Sirets list
         * @return { Object } Response knowledge
         */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.getKnowledge}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({ sirets: sirets}),
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
      }
    }

}
export  { Knowledge };