// @flow
import React, { Component } from "react";
import { Grid, Transition, Header, Icon, Image, Container, List } from "semantic-ui-react";
import { Wrapper, Loading } from "components";
import { articles } from 'constantes';

import './Article.scss';

type Props = {
    location: any
}

type State = {
    visible: boolean,
    loading: boolean,
    article: any
}

/**
 * Article Container
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Article content
 */
class Article extends Component<Props,State> {

    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            visible: false,
            article: {}
        }
    }
   
    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Article
         * @method componentDidMount
         * @return { Void } 
         */
        const context = this.props.location.state;
        await this.setState({ 
            loading: true, 
            visible: true ,
            article: articles[context]
        });
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Article
         * @return { String } JSX 
         */
        const { visible, article, loading } = this.state;
        return(
            <Grid className="Article page bg-gradient">
                <Grid.Row>
                    <Grid.Column>
                    <Transition visible={visible} animation="fade up" duration={500}>
                        <Header as="h2" textAlign="center" className="lead">
                            {article.title}
                        </Header>
                    </Transition>
                    {loading ? (
                        <Wrapper name="block" >
                            {article.contents.map((article, index) => {
                                return (
                                    article.map((content, index) => {
                                        const components = {
                                            Header: Header,
                                            Icon: Icon,
                                            Image: Image,
                                            Container: Container,
                                            List: List
                                        };
                                        const name = Object.keys(content)[0];
                                        const props = Object.values(content)[0];
                                        const Component = components[name]
                                        return (<Component key={index} {...props} />)
                                    })
                                )
                            })}
                        </Wrapper>
                    ) : (
                        <Wrapper name="block">
                            <Loading title="Chargement" />
                        </Wrapper>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}
export { Article }