// @flow
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Image, Icon } from "semantic-ui-react";
import { LocalStorage } from "services";
import { withRouter } from "react-router-dom";

import "./Header.scss";

type Props = {
    location: any,
    history: any,
    match: any,
};

type State = {
    error: boolean,
    logo: string,
};

/**
 * Header Component
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description Header Menu component
 */
class WithRouterHeader extends Component<Props, State> {
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props);
        this.state = {
            error: true,
            logo: "",
        };
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Components.WithRouterHeader
         * @method componentDidMount
         * @return { Void }
         */
        const logo = await LocalStorage.getItem("logo");
        await this.setState({
            logo: logo,
        });
    }

    handleGoBack = () => {
        /**
         * @instance
         * @method handleGoBack
         * @memberof Components.WithRouterHeader
         * @description History back
         * @return { Void }
         */
        if (
            this.props.location.state &&
            this.props.location.state.scope === "entreprise"
        )
            this.props.history.prev = "entreprise";
        this.props.history.goBack();
    };

    render() {
        /**
         * @instance
         * @method render
         * @memberof Components.WithRouterHeader
         * @return { String } JSX
         */

        const { pathname } = this.props.location;

        return (
            <Fragment>
                <Menu secondary fixed="top" className="Header" fluid widths={3}>
                    <Menu.Item className="menu-left">
                        {pathname !== "/" && pathname !== "/errors" && (
                            <Icon
                                onClick={this.handleGoBack}
                                name="chevron left"
                                size="large"
                            />
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="/">
                            <Image size="small" src={this.state.logo} />
                        </Link>
                    </Menu.Item>
                    <Menu.Item className="menu-right"></Menu.Item>
                </Menu>
            </Fragment>
        );
    }
}
const Header = withRouter(WithRouterHeader);
export { Header };
