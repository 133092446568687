
import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { Wrapper } from "components";
import { Button, List, Modal, Icon } from 'semantic-ui-react'
import { userChatBot } from "constantes";
import { isEmptyObject} from "tools";
import './CustomComponent.scss';

/**
 * CustomComponent 
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description ChatBot CustomComponent container
 */
class CustomComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            loading: true,
            trigger: false,
            modal: false,
            filters: false,
            conditions: false,
            reset: false,
            title: '',
        };
    }

    async UNSAFE_componentWillMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.CustomComponent
         * @method UNSAFE_componentWillMount
         * @return { Void } 
        */
        const payload = await this.getPayload();
        const filters = (payload.params && payload.params.filters)  ? true : false;
        const conditions = (payload.params && payload.params.conditions)  ? true : false;
        const reset = (payload.params && payload.params.reset)  ? true : false;
        await this.setState({ 
            loading: false, 
            filters: filters,
            conditions: conditions,
            reset: reset,
            payload: payload
        });
    }
    
    getPayload = async () => {
        /**
         * @instance
         * @async
         * @memberof Containers.CustomComponent
         * @method getPayload
         * @return { Void } 
        */
       
        let type =  "text"
        let message = {
            text: this.props.previousStep.value,
        }
        if(this.props.previousStep.id === 'search'){
          
            await this.props.consumer.clearCache();
        }
       
        const cache = await this.props.consumer.getCache();

        if(this.props.previousStep.value){
            if(this.props.previousStep.value.hasOwnProperty('path')){
                delete message.text
      
                type = this.props.previousStep.value.path.query ? 'research' : 'quick_reply';
   
                message.payload = this.props.previousStep.value.path;
                message.title = this.props.previousStep.value.title
            }else{
                type = 'text'
                message = {
                    text: this.props.previousStep.value
                }
            }
        }
        try{
            
            if(!isEmptyObject(cache) && isEmptyObject(this.props.previousStep)){  
               this.props.triggerNextStep({ trigger: 'search'} );
                return { messages : [{type: "text", content: {text: `Les résultats de votre dernière recherche ${cache.length} CCN`}}] ,data: cache};
                
            }else{

                if(message.text === undefined && !message.payload)    {
                    type = 'text'
                    message = {
                        text: 'bonjour'
                    }
                }

                const response = await this.props.consumer.messaging(type, message);

                if(!response.messages[0].content.quick_replies){
                  
                    if(response.source_type === 'quick_reply'){
                        this.props.triggerNextStep({value: { source : response.source },  trigger: 'search'} );
                    }
                    if(!response.messages[response.messages.length-1].content.quick_replies && !message.payload){
                       // this.props.triggerNextStep({ trigger: 'search'} );
                    }
                }
             
                if((response.params && response.params.end )){
                    
                    this.props.triggerNextStep({ trigger: 'search'} );
                }
               if(response.data){
                    
                    if(Array.isArray(response.data)){
                       await this.props.consumer.setCache(response.data);
                    }
                }
                return response
            }
           
        }catch (error){
            this.props.triggerNextStep({ trigger: '4'} );
            return false;
        }
      
    }

    reset = async () => {
        /**
         * @instance
         * @async
         * @method reset
         * @memberof Containers.CustomComponent
         * @description Reset
         * @return { Void } 
         */
        await this.setState({ filters: false, conditions: false , reset: false}, () => {
            this.props.triggerNextStep({ trigger: 'search'} );
        });

    }

    triggetNext = async ({ path, title }) => {
        /**
         * @instance
         * @async
         * @method triggetNext
         * @memberof Containers.CustomComponent
         * @description Trigger step
         * @param { String } path Step
         * @param { String } title Title
         * @return { Void } 
         */
        await this.setState({ trigger: true,  title: title }, () => {
            this.props.triggerNextStep({ value: { path : path , title: title}, trigger: 3} );
            
        });
    }

    handleModalOpen = () => {
        /**
         * @instance
         * @async
         * @method handleModalOpen
         * @memberof Containers.CustomComponent
         * @description Open confirmation modal
         * @return { Void } 
         */
        this.setState({ modal: true });
    }
     
    handleModalClose = () => {
        /**
         * @instance
         * @async
         * @method handleModalClose
         * @memberof Containers.CustomComponent
         * @description Close confirmation modal
         * @return { Void } 
         */
        this.setState({ modal: false });
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.CustomComponent
         * @return { String } JSX 
        */
        const { loading, payload, modal, title, filters, conditions, reset } = this.state;
        return (
            <Wrapper style={{ width: '100%'}}>
                <Modal className='result' open={modal} centered={false}>
                    <Modal.Header>Les résultats</Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description>
                            <List divided verticalAlign='middle'>
                                {(payload.data && Array.isArray(payload.data)) && (
                                    <Fragment>
                                        {payload.data.map((item, index) => (
                                            <List.Item key={index}>
                                            <List.Content floated='right'>
                                            <Link className="ui basic button mini" to={{pathname:`/ccn/${item.ref}`, state: {
                                                    id: item.ref,
                                                    idcc: item.idcc,
                                                    title: item.description,
                                                    description: item.description,
                                                    scope: 'ccn'
                                                }}}>Consulter
                                                </Link>
                                            </List.Content>
                                            <List.Header>IDCC {item.idcc}</List.Header>
                                            <List.Content> {item.description} </List.Content>
                                            </List.Item>
                                            
                                        ))}
                                    </Fragment>
                                )}
                            </List>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className="button btn default" size='small' onClick={this.handleModalClose}>Fermer </Button>
                    </Modal.Actions>
                </Modal>
                {loading &&
                <Fragment>
                    <Wrapper name="rsc-custom-cs rsc-custom-ts-bot">
                        <Wrapper name="rsc-custom-ts-image-container">
                            <img className="rsc-custom-ts-image" src={userChatBot} alt="avatar"/>
                        </Wrapper>
                        <div className="rsc-custom-ts-bubble"><span className="rsc-loading" >...</span> </div>
                    </Wrapper>
                </Fragment>
            }
            {payload.messages && (
                <Fragment >
                    {payload.messages.map((item, index ) => {
                    return(
                        <div key={index} className="rsc-custom-cs rsc-custom-ts-bot">

                            <div className="rsc-custom-ts-image-container">
                                <img className="rsc-custom-ts-image" src={userChatBot} alt="avatar"/>
                            </div>
                            <div className="rsc-custom-ts-bubble"> 
                                <Fragment>
                                    {item.content.hasOwnProperty('text') && !item.content.hasOwnProperty('text_message') && item.content.text}
                                    {item.content.hasOwnProperty('text_message') && item.content.text_message}
                                </Fragment>
                            </div>
                            
                            {item.content.quick_replies && (
                                <div className="rsc-custom-cs">
                                    <ul className="rsc-custom-os-option">
                                        {item.content.quick_replies.map((item, index) => (
                                            <li key={index} className="rsc-custom-os-option">
                                                <button 
                                                    className="rsc-custom-os-option-element" 
                                                    key={index} 
                                                    onClick={() => this.triggetNext({ path: item.payload, title: item.title})}>
                                                    {item.title}
                                                </button>
                                            </li>
                                        ))}
                                       {reset &&
                                            <li className="rsc-custom-os-option">
                                                <Button basic icon labelPosition='left' className="rsc-custom-os-option-element redo"  onClick={this.reset}>
                                                <Icon name='redo'  />
                                                Recommencer
                                                </Button>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            )}
                            {item.content.buttons && (
                                <div className="rsc-custom-os">
                                    <ul className="rsc-custom-os-option">
                                        {item.content.buttons.map((item, index) => (
                                            <li key={index} className="rsc-custom-os-option">
                                                <button 
                                                    key={index}
                                                    className="rsc-custom-os-option-element"
                                                    onClick={() => this.triggetNext({ path: item.payload, title: item.title})}
                                                >
                                                {item.title}</button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )} 

                        {(payload.data && Array.isArray(payload.data)) && (
                         
                            <div className="rcs-custom-os">
                                 <ul className="rsc-custom-os-option">
                                 <li className="rsc-custom-os-option">
                                    <button className="rsc-custom-os-option-element" onClick={this.handleModalOpen}>
                                        Voir les résultats
                                    </button></li>
                                    {filters && 
                                    <Fragment>
                                     <li className="rsc-custom-os-option">
                                         <button className="rsc-custom-os-option-element" ref={this.filtersRef} onClick={() => this.triggetNext({ path: payload.params.input, title: 'oui'})}>
                                         Filtrer par garanties
                                    </button>
                                    </li>
                                    
                                 
                                       <li className="rsc-custom-os-option">
                                     
                                       <Button basic icon labelPosition='left' className="rsc-custom-os-option-element redo"  onClick={this.reset}>
                                            <Icon name='redo'  />
                                            Annuler
                                         </Button>
                                    
                                    </li>
                                  
                                    </Fragment>
                                    }
                                    {conditions &&
                                       <Fragment>
                                            
                                        <li className="rsc-custom-os-option">   
                                            <button className="rsc-custom-os-option-element" ref={this.filtersRef} onClick={() => this.triggetNext({ path: payload.params.input, title: 'oui'})}>
                                                Ajouter des conditions 
                                            </button>
                                        </li>
                                  
                                        <li className="rsc-custom-os-option">
                                            <Button basic icon labelPosition='left' className="rsc-custom-os-option-element redo"  onClick={this.reset}>
                                                <Icon name='redo'  />
                                                Annuler
                                             </Button>
                                         </li>
                                       
                                        </Fragment>
                                       }
                                    </ul>
                            </div>
                        )}
                        </div>
                    )
                })}
                  {title && (
                    <div className="rsc-custom-ts rsc-custom-ts-user">
                        <div className="rsc-custom-ts-bubble">{title}</div>
                        <div className="rsc-custom-ts-image-container"><img className="rsc-custom-ts-image" src={userChatBot} alt="avatar"/></div>
                    </div>
                     )}
                </Fragment>
                ) }
            </Wrapper>
 
            );
    }
}

export { CustomComponent }