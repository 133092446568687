// @flow
import React from 'react';

/**
 * Consumer Reducer
 * @author Tomasz tpr@deltacode.fr
 * @function
 * @memberof Routes.WithRouterConsumerRoute
 * @description Reduce consumers
 * @return {Component} Reducer component  
 */
const ConsumerReducer = (Component:any, consumers:any, props:any) => {
    return consumers.reduceRight(
        (inner, Component) => (...args) => (
            <Component.consumer>
            {value => inner(...args, {name: Component.name, consumer: value })}
            </Component.consumer>
        ),
        ( ...values) => { 
            let consumers = [];
            [...values].map((value, index) => {
                return consumers[value.name] = value.consumer
            });
            return(<Component consumers={consumers} {...props} />)
        }
    )();
}

export { ConsumerReducer } ;