// @flow
import React from 'react';
import { Route, withRouter } from "react-router-dom";
import { ConsumerReducer } from 'routes';

type Props = {
    component: any,
    consumers: any
}

/**
 * WithRouterConsumerRoute
 * @author Tomasz tpr@deltacode.fr
 * @memberof Routes
 * @description Protected Route Component
 */
class WithRouterConsumerRoute extends React.Component<Props> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Routes.WithRouterConsumerRoute
         * @return { String } JSX 
         */
        const { component:RouteComponent, consumers, ...rest}:any = this.props
        return (
            <Route
                render={props => 
                    ConsumerReducer( RouteComponent, consumers, props)
                }
                {...rest}
            />
        )
    }
}
const ConsumerRoute = withRouter(WithRouterConsumerRoute);
export { ConsumerRoute } 