// @flow
import { LocalStorage } from 'services/storageService';
import { checkStatus, checkError } from 'tools';

/**
 * ChatBot service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description ChatBot service
 */
class ChatBot {

    static entrypoints:any = {
        messaging : 'apps/ccn/chatbot/messaging'
    }
    
    static url: any = process.env.REACT_APP_CHATBOT_API;

    static messaging = async (type: string, message: any) => {
        /**
         * Messaging
         *
         * @async
         * @method messaging
         * @memberof Services.ChatBot
         * @param { String } type message type
         * @param { String } message message to ask
         * @return { String } Chatbot response
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const issuer = await LocalStorage.getItem('issuer') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.messaging}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors',
                body: JSON.stringify({type: type, user_id: issuer, message: message}),
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error)
        }
    }
}
export  { ChatBot };