// @flow
import React, { Component } from 'react';
import { Grid, Header, Popup, Button } from 'semantic-ui-react';
import { numberFormat, getKeyFiguresYears} from 'tools';

type Props = {
    company: any,
}

type State = {};

 /**
 * KeyFigures
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description KeyFigures component
 */
class KeyFigures extends Component<Props, State> {
    
    render() {
        const { keyFigures } = this.props.company;
        /**
         * @instance
         * @method render
         * @memberof Components.KeyFigures
         * @return { String } JSX 
         */
        return (
            <Popup
                flowing
                position='top center'
                trigger={<Button  style={{marginLeft: '0.5em'}} size= 'mini' icon='info circle' />}
                content={
                    <Grid centered divided columns={3}>
                        {[
                            {...keyFigures[`chiffre${getKeyFiguresYears().n2}`], year: getKeyFiguresYears().n2},
                            {...keyFigures[`chiffre${getKeyFiguresYears().n3}`], year: getKeyFiguresYears().n3},
                            {...keyFigures[`chiffre${getKeyFiguresYears().n4}`], year: getKeyFiguresYears().n4}
                            ].map((item, index)=>(
                                <Grid.Column key={index} textAlign='center'>  
                                    <Header as='h4'>{item.year}</Header>
                                    <p>
                                        CA: <b>{numberFormat(item.ca)}</b> 
                                    </p>
                                    <p>
                                        Résultat: <b>{numberFormat(item.resultat)}</b> 
                                    </p>
                                </Grid.Column>        
                        ))}    
                    </Grid>
                }
                basic
            />
        )
    }
}
export { KeyFigures };