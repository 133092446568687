// @flow
import React from 'react';
import { LocalStorage } from 'services/storageService';
import { Ccn } from 'services/ccnService';

const CcnContext = React.createContext<any>();

type Props = {
    children: any
}

type State = {};

/**
 * CcnProvider
 * @author Tomasz tpr@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description Ccn provider
 */
class CcnProvider extends React.Component<Props,State> {

    getCcn  = async (id: string) => {
        /**
         * @instance
         * @async
         * @method getCcn
         * @memberof Providers.CcnProvider
         * @param { String } id Ccn id
         * @return { Object } Ccn
         */
        try {
            const response = await Ccn.get(id);
            const payload = await response.json();
            return payload
        }
        catch(error) {
            throw error
        }
    }

    search = async (query: string) => {
        /**
         * @instance
         * @async
         * @method search
         * @memberof Providers.CcnProvider
         * @param { String } query Query parameters
         * @return { Array } Ccns list
         */
        try {
            const response = await Ccn.search(query);
            const payload = await response.json();
            return payload
        }
        catch(error) {
            throw error
        }
    }

    getLocalCcn = async () => {
        /**
         * @instance
         * @async
         * @method getLocalCcn
         * @memberof Providers.CcnProvider
         * @return { Object } LocalStorage Ccn
         */
        return await LocalStorage.getItemAsJSON('ccn');
    }

   setLocalCcn = async (data:any) => {
        /**
         * @instance
         * @async
         * @method getLocalCcn
         * @memberof Providers.CcnProvider
         * @param { Object } data Ccn 
         * @return { Void }
         */
        return await LocalStorage.setItemAsJSON('ccn', data);
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.CcnProvider
         * @return { String } JSX 
         */
        return (
            <CcnContext.Provider
                value={{
                    ...this.state,
                    getCcn: this.getCcn,
                    search: this.search,
                    getLocalCcn: this.getLocalCcn,
                    setLocalCcn: this.setLocalCcn
                }}>
                {this.props.children}
            </CcnContext.Provider>
        )
    }
}

const CcnConsumer = CcnContext.Consumer

export { CcnProvider, CcnConsumer }