// @flow
import React, { Component } from 'react';

type Props = {
    children: any,
    providers: any
}

/**
 * Provider 
 * @author Tomasz tpr@deltacode.fr
 * @memberof App
 * @extends {React.Component}
 * @description App provider
 */
class Provider extends Component<Props> {

    combine = (providers:any, content:any) => {
        /**
         * @instance
         * @method combine
         * @memberof App.Provider
         * @param { Object } providers List providers
         * @param { Object } content Providers content
         * @return { String } Provider
         */
        providers.forEach((Provider) => {
            content = React.cloneElement(<Provider/>, null, content);
        });
        return content
    }
 
    render() {
        /**
         * @instance
         * @method render
         * @memberof App.Provider
         * @return { String } JSX 
         */
        const { children , providers } =this.props

        let content = children || null;
        const numberOfProviders = providers.length;
      
        if (!numberOfProviders) {
          return content;
        }
      
        return this.combine(providers, content)
    }
}

export default Provider;