// @flow
import { LocalStorage } from './storageService';
import { checkStatus, checkError } from 'tools';

/**
 * Geolocatele service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description Geolocate service
 */
class Geolocate {
  
    static url:any = process.env.REACT_APP_GEOLOCATE_API;

    static entrypoints:any = {
        geolocate : 'apps/ccn/geolocate'
    }

    static geocode = async(address:string) =>{
        /**
         *  Geocode
         *
         * @async
         * @method Geocode
         * @memberof Services.Geolocate
         * @param { String } address Adress
         * @return { Array } Formatted array
        */
        try{
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.geolocate}?address=${address}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);

        }catch(error){
            throw checkError(error);
        }
    }

    static reverseGeocode = async (latlng:string) => {
        /**
         * Reverse Geocode
         *
         * @async
         * @method reverseGeocode
         * @memberof Services.Geolocate
         * @param { String } latlng Coordinates
         * @return { String } Formatted address
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.geolocate}?language=fr&latlng=${latlng}&result_type=street_address`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }
}

export  { Geolocate };