// @flow

/**
 * SessionStorage service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description SessionStorage and LocalStorage service
 */
class SessionStorage  {
    
    static async setItems(items:any):Promise<any> {
        /**
         * Set items
         *
         * @async
         * @method setItems
         * @memberof Services.SessionStorage
         * @param items Items to set
         * @return { Object } Items set
        */
        Object.keys(items).map((key) => {
            return Promise.resolve(this.setItem(key, items[key]));
        });
    }

    static async setItem(key:string, value:any):Promise<{}> {
        /**
         * Set item
         *
         * @async
         * @method setItem
         * @memberof Services.SessionStorage
         * @param key key of item
         * @param value value of item to set
         * @return { Object } Item set
        */
        sessionStorage.setItem(key, value);
        return Promise.resolve(value);
    }

    static async setItemAsJSON(key:string, value:any):Promise<{}> {
        /**
         * Set item as JSON
         *
         * @async
         * @method setItemAsJSON
         * @memberof Services.SessionStorage
         * @param key key of item
         * @param value value of item to set
         * @return { Object } Item set in JSON
        */
        sessionStorage.setItem(key, JSON.stringify(value));
        return Promise.resolve(value);
    }       

    static async getItem(key:string):Promise<any> {
        /**
         * Get item
         *
         * @async
         * @method getItem
         * @memberof Services.SessionStorage
         * @param key key of item to get
         * @return { Object } Item 
        */
        return Promise.resolve(sessionStorage.getItem(key));
    }

    static async getItemAsJSON(key:string):Promise<any> {
        /**
         * Get item as JSON
         *
         * @async
         * @method getItemAsJSON
         * @memberof Services.SessionStorage
         * @param key key of item to get
         * @return { Object } Item 
        */
        return Promise.resolve(JSON.parse(sessionStorage.getItem(key)|| '{}'));
    }

    static async removeItem(key:string):Promise<any> {
        /**
         * Remove item
         *
         * @async
         * @method removeItem
         * @memberof Services.SessionStorage
         * @param key key of item to remove
         * @return { Object } Item  removed
        */
        return Promise.resolve(sessionStorage.removeItem(key));
    }

    static async removeItems(items:any):Promise<any> {
        /**
         * Remove items
         *
         * @async
         * @method removeItems
         * @memberof Services.SessionStorage
         * @param items items to remove
         * @return { Object } Items  removed
        */
        items.map((key) => {
            return Promise.resolve(this.removeItem(key));
        });
    }

    static async clear():Promise<any>{
        /**
         * Clear
         *
         * @async
         * @method clear
         * @memberof Services.SessionStorage
         * @return { Void } 
        */
        return Promise.resolve(sessionStorage.clear());
    }
}

/**
 * LocalStorage service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description LocalStorage
 */
class LocalStorage  {

    static async setItems(items:any):Promise<any> {
        /**
         * Set items
         *
         * @async
         * @method setItems
         * @memberof Services.LocalStorage
         * @param items Items to set
         * @return { Object } Items set
        */
        Object.keys(items).map((key) => {
            return Promise.resolve(this.setItem(key, items[key]));
        });
    }

    static async setItem(key:string, value:any):Promise<any> {
        /**
         * Set item
         *
         * @async
         * @method setItem
         * @memberof Services.LocalStorage
         * @param key key of item
         * @param value value of item to set
         * @return { Object } Item set
        */
        localStorage.setItem(key, value);
        return Promise.resolve(value);
    }

    static async setItemAsJSON(key:string, value:any):Promise<{}> {
        /**
         * Set item as JSON
         *
         * @async
         * @method setItemAsJSON
         * @memberof Services.LocalStorage
         * @param key key of item
         * @param value value of item to set
         * @return { Object } Item set in JSON
        */
        localStorage.setItem(key, JSON.stringify(value));
        return Promise.resolve(value);
    }    

    static async getItem(key:string):Promise<any> {
        /**
         * Get item
         *
         * @async
         * @method getItem
         * @memberof Services.LocalStorage
         * @param key key of item to get
         * @return { Object } Item 
        */
        return Promise.resolve(localStorage.getItem(key));
    }

    static async getItemAsJSON(key:string):Promise<any> {
        /**
         * Get item as JSON
         *
         * @async
         * @method getItemAsJSON
         * @memberof Services.LocalStorage
         * @param key key of item to get
         * @return { Object } Item 
        */
        return Promise.resolve(JSON.parse(localStorage.getItem(key) || '{}'));
    }

    static async removeItem(key:string):Promise<any> {
        /**
         * Remove item
         *
         * @async
         * @method removeItem
         * @memberof Services.LocalStorage
         * @param key key of item to remove
         * @return { Object } Item  removed
        */
        return Promise.resolve(localStorage.removeItem(key));
    }

    static async removeItems(items:any):Promise<any> {
        /**
         * Remove items
         *
         * @async
         * @method removeItems
         * @memberof Services.LocalStorage
         * @param items items to remove
         * @return { Object } Items  removed
        */
        items.map((key) => {
            return Promise.resolve(this.removeItem(key));
        });
    }

    static async clear():Promise<any>{
        /**
         * Clear
         *
         * @async
         * @method clear
         * @memberof Services.LocalStorage
         * @return { Void } 
        */
        return Promise.resolve(localStorage.clear());
    }
}

export { SessionStorage, LocalStorage };