// @flow
import React, { Component } from 'react';
import { List } from 'semantic-ui-react';
import { formatAddress } from 'tools';

type Props = {
    data: any,
    address: boolean,
}

type State = {};

 /**
 * Knowledge
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description Knowledge component
 */
class Knowledge extends Component<Props, State> {
    
    render() {
        const { adresse } = this.props.data;
        const { phone, emails, website, socials } = this.props.data.knowledge;
        /**
         * @instance
         * @method render
         * @memberof Components.Knowledge
         * @return { String } JSX 
         */
        return (
            <List>
                {this.props.address && adresse && 
                    <List.Item>
                        <List.Icon color="grey" name='marker'/>
                            <List.Content>{formatAddress(adresse)}</List.Content>
                    </List.Item>
                }
                {phone &&
                    <List.Item>
                        <List.Icon flipped="horizontally" color="grey" name='phone'/>
                        <List.Content>{ phone }</List.Content>
                    </List.Item>
                }
                {emails && emails.map((email,index) =>(
                    <List.Item key={index}>
                        <List.Icon color="grey" name='mail'/>
                        <List.Content>
                            <a href={email}>{email}</a>
                        </List.Content>
                    </List.Item>
                ))}
                {website && 
                    <List.Item>
                        <List.Icon color="grey" name='linkify'/>
                        <List.Content>
                            <a href={website}>{website}</a>
                        </List.Content>
                    </List.Item>
                }
                {socials && socials.map((social,index)=>(
                    <List.Item key={index}>
                        <List.Icon color="grey" name={`${social.name.toLowerCase()} square`}/>
                        <List.Content>
                            <a href={social.link}>
                                {social.link}
                            </a>
                        </List.Content>
                    </List.Item> 
                ))}
            </List>
        )
    }
}
export { Knowledge };