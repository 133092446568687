// @flow
import React, { Component } from 'react';
import { Container, Header } from 'semantic-ui-react';
import { resolvePath, stringConcat } from 'tools'

import './WidgetText.scss';

type Props = {
    align: string,
    header: string,
    paragraph: any,
    data: any
}

type State = {};

/**
 * WidgetText widget
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description Widget with text content
 * @return {ReactComponent} WidgetText  
 */
class WidgetText extends Component<Props,State> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetText
         * @return { String } JSX 
         */
        const { align, data} = this.props;
        const header = Array.isArray(this.props.header) ? stringConcat(data, this.props.header) : resolvePath(data, this.props.header);
        const paragraph = this.props.paragraph 
            ? Array.isArray(this.props.paragraph) ? stringConcat(data, this.props.paragraph) : resolvePath(data, this.props.paragraph) 
            : "";
  
        return (
            <Container className="Widget-text" textAlign={align}>  
                <Header className='color-default' as='h3' content={header}/>
                    <p>{paragraph}</p> 
            </Container>
        );
    }
}

export { WidgetText }