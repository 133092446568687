// @flow
import React, { Component, Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import { Wrapper } from "components";
import { resolvePath, stringConcat } from 'tools';

import './WidgetCircularPercent.scss';

type Props = {
    title: string,
    percent: string,
    paragraph: any,
    data: any
}

type State = {};

/**
 * WidgetCircularPercent widget
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description WidgetCircularPercent
 * @return {ReactComponent} WidgetCircularPercent  
 */
class WidgetCircularPercent extends Component<Props,State>  {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetCircularPercent
         * @return { String } JSX 
         */
        const { data } = this.props 
        const title =  resolvePath(data, this.props.title);
        const percent = resolvePath(data, this.props.percent) || 0;
        const paragraph = stringConcat(data, this.props.paragraph);

        return (
            <Fragment>
                { paragraph ? (
                <Fragment>
                    <Header size='medium' className='color-default' textAlign='center' content={title}/> 
                    <Wrapper name={"center c100 p"+Math.floor(percent)}>
                        <span>{percent}<small>%</small></span>
                        <Wrapper name="slice">
                            <Wrapper name="bar"></Wrapper>
                            <Wrapper name="fill"></Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper name='paragraph' style={{ marginTop: '1em', fontSize: '1.2rem' }}>{paragraph}</Wrapper>
                </Fragment>
                ) : (
                    null
                )}
            </Fragment>
        );
    }
}

export { WidgetCircularPercent }