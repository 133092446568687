// @flow
import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';

import './Loading.scss'; 

type Props = {
    title: string
}

type State = {
    title: string
};

/**
 * Loading
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description Loading component
 */
class Loading extends Component<Props, State>  {
    
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            title: ""
        }
    }
    
    componentDidMount() {
        /**
         * @instance
         * @memberof Components.Loading
         * @method componentDidMount
         * @return { Void } 
         */
        this.setState({
            title: this.props.title
        })
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Components.Loading
         * @return { String } JSX 
         */
        const { title } = this.state
        return (
            <Loader active inline='centered' size='big' content={title} />
        );
    }
}

export { Loading };