// @flow
import React, { Component, Fragment } from "react";
import {
    Grid,
    Segment,
    Header,
    Divider,
    Transition,
} from "semantic-ui-react";
import { resolvePath } from "tools";
import * as widgets from "widgets";

import "./WidgetBlockNested.scss";

type Props = {
    title: string,
    align: string,
    centered: boolean,
    responsive: any,
    content: any,
    data: any,
};

type State = {
    visible: boolean,
};

/**
 * WidgetBlockNested
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description Nested block contains the other widgets
 */
class WidgetBlockNested extends Component<Props, State> {
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props);
        const visible = true;
        this.state = {
            visible: visible,
        };
    }

    toggleVisibility = () => {
        /**
         * @instance
         * @method toggleVisibility
         * @memberof Widgets.WidgetBlockNested
         * @description Change hidden status
         * @return { Void }
         */
        this.setState((prevState) => ({ visible: !prevState.visible }));
    };

    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetBlockNested
         * @return { String } JSX
         */
        const visible = this.state.visible;
        const { data, title, content, responsive, centered, align } = this.props;
        const header = title ? resolvePath(data, title) : "";
        return (
            <Fragment>
                {data && content && (
                    <Segment  className="Widget-block-nested">
                        {content.length  ? (
                            <Header
                                className="title"
                                as="h4"
                                floated="left"
                                content={header}
                            />
                        ) : (
                            <Header
                                className="title center"
                                as="h4"
                                textAlign="center"
                                content={header}
                            />
                        )}
                        <Transition
                            visible={visible}
                            animation="fade"
                            duration={200}
                        >
                            <Grid
                               centered={centered}
                            >
                                {title &&
                                ( content.length) ? (
                                    <Divider />
                                ) : null}
                                {content.length ? (
                                    <Grid.Row>
                                        {content.map(
                                            (element, index) => {
                                               
                                                const Widget =
                                                    widgets[element.widget];
                                                
                                                return (
                                                    <Grid.Column
                                                    key={index}
                                                    textAlign={align}
                                                    mobile={
                                                        responsive
                                                            .mobile
                                                    }
                                                    tablet={
                                                        responsive
                                                            .tablet
                                                    }
                                                    computer={
                                                        responsive
                                                            .computer
                                                    }
                                                    >
                                                        <Widget
                                                            {...element.params}
                                                            data={data}
                                                        />
                                                    </Grid.Column>
                                                );
                                            }
                                        )}
                                    </Grid.Row>
                                ) : null}
                            </Grid>
                        </Transition>
                    </Segment>
                )}
            </Fragment>
        );
    }
}

export { WidgetBlockNested };
