// @flow
import React  from 'react';
import { Switch} from "react-router-dom";
import { ProtectedRoute, ConsumerRoute } from 'routes';

import { 
    ConnectionConsumer,
    CcnConsumer, 
    CompaniesConsumer,
    ChatBotConsumer,
    } from 'providers';
    
import { 
    Dashboard,
    Auth, 
    Finder, 
    Ccn, 
    CcnDetail, 
    Companies, 
    Company, 
    Articles, 
    Article,
    ChatBot,
    } from 'containers';
    
const routes = {
    protected: [
        { 
            path: "/ccn/:id/detail", 
            component: CcnDetail,
            consumers: [
                { name: 'ccns', consumer: CcnConsumer}
            ]
        },
        { 
            path: "/ccn/:id", 
            component: Ccn, 
            consumers: [
                { name: 'ccns', consumer: CcnConsumer},
            ]
        },
        { 
            path: "/recherche/:scope",
            component: Finder, 
            consumers: [
                { name: 'ccns', consumer: CcnConsumer},
            ]
        },
        { 
            path: "/entreprises/:id", 
            component: Companies, 
            consumers: [
                { name: 'companies', consumer: CompaniesConsumer},
                { name: 'ccns', consumer: CcnConsumer},

            ]
        },
        { 
            path: "/entreprise/:siren", 
            component: Company,
            consumers: [
                { name: 'companies', consumer: CompaniesConsumer},
                { name: 'ccns', consumer: CcnConsumer}
            ]
        },
        { 
            path: "/chatbot", 
            component: ChatBot, 
            consumers: [
                { name: 'chatbot', consumer: ChatBotConsumer},
            ]
        },
        { 
            path: "/articles",
            component: Articles
        },
        { 
            path: "/article",
            component: Article
        },
        { 
            path: "/",
            component: Dashboard,
            
        },
    ],
    consumers :  [

        { 
            path: "/", 
            component: Auth, 
            consumers: [
                { name: 'connection', consumer: ConnectionConsumer}, 
            ]
        }
    ]
}

type Props = {
    component: any,
    consumers?:  Array<{| consumer: any, name: string |}>,
    authenticated: boolean,
    authorizedParts: Array<string>,
    owner: boolean,
    expiredToken: any,
    logout: any
}

type State = {
    expired: boolean
}


/**
 * PrivateRoutes
 * @author Tomasz tpr@deltacode.fr
 * @memberof Routes
 * @description PrivateRoutes Component
 */
class PrivateRoutes extends React.Component<Props,State> {
    
    render() {
        /**
         * @instance
         * @method render
         * @memberof Routes.PrivateRoutes
         * @return { String } JSX 
         */
        const  { authenticated, expiredToken,  logout} = this.props
        return (
            <Switch>
                {routes.protected.map((route, index) => {
                    return <ProtectedRoute
                            key={index}
                            path={route.path} 
                            component={route.component}
                            consumers={route.consumers ? route.consumers : [] }
                            authenticated={authenticated}
                            expiredToken={expiredToken}
                            logout={logout}
                        /> 
                    })}
            </Switch>
        );
    }
}

/**
 * PublicRoutes
 * @author Tomasz tpr@deltacode.fr
 * @description PublicRoutes Component
 */
class PublicRoutes extends React.Component<{}> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Routes.PublicRoutes
         * @return { String } JSX 
         */
        return (
            <Switch>
                {routes.consumers.map((route, index) => {
                    return <ConsumerRoute
                        key={index}
                        path={route.path} 
                        component={route.component}
                        consumers= {route.consumers}
                    /> 
                })}                
            </Switch>
        );
    }
}
export { PrivateRoutes, PublicRoutes } 