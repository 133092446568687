// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { LocalStorage } from "services";
import { Wrapper } from "components";
import { Grid, Header, Transition, Icon } from "semantic-ui-react";

import "./Dashboard.scss";

type Props = {
    consumers: any,
    match: any,
};

type State = {
    visible: boolean,
    authorizedParts: Array<string>,
};

/**
 * Dashboard
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Dashboard container
 */
class Dashboard extends Component<Props, State> {
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props);
        this.state = {
            authorizedParts: [],
            visible: false,
        };
    }

    async componentDidMount() {
        /**
         * @instance
         * @memberof Containers.Dashboard
         * @method componentDidMount
         * @return { Void }
         */
        const authorizedParts = await LocalStorage.getItem("authorizedParts");

        this.setState({ visible: true, authorizedParts: authorizedParts });
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Dashboard
         * @return { String } JSX
         */
        const { visible, authorizedParts } = this.state;
        return (
            <Grid className="Dashboard page bg-gradient">
                <Grid.Row>
                    <Grid.Column>
                        <Transition
                            visible={visible}
                            animation="fade up"
                            duration={500}
                        >
                            <Header as="h2" textAlign="center" className="lead">
                                Quelles CCN <br />
                                pour quelles entreprises ?
                            </Header>
                        </Transition>
                        <Link
                            className="link medium center"
                            to="/recherche/ccn"
                        >
                            <Icon circular name="search" />
                            <Wrapper name="link-content">
                                Les obligations{" "}
                                <span className="color-default-light">
                                    par CCN
                                </span>
                            </Wrapper>
                        </Link>
                        {authorizedParts.includes("entreprises") && (
                            <Link
                                className="link medium center"
                                to="/recherche/entreprises"
                            >
                                <Icon circular name="building" />
                                <Wrapper name="link-content">
                                    Recherche{" "}
                                    <span className="color-default-light">
                                        d'Entreprises
                                    </span>
                                </Wrapper>
                            </Link>
                        )}
                        {authorizedParts.includes("chatbot") && (
                            <Link className="link medium center" to="/chatbot">
                                <Icon circular name="comments" />
                                <Wrapper name="link-content">
                                    Les CCN{" "}
                                    <span className="color-default-light">
                                        par opportunités
                                    </span>
                                </Wrapper>
                            </Link>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export { Dashboard };
