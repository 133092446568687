// @flow
import React, { Component } from 'react';
import { Header, Segment, Button, Modal, Icon, Search } from 'semantic-ui-react';
import { Slider } from "react-semantic-ui-range";

type Props = {
    radius: number,
    modal:boolean,
    handleSearchChange: any,
    handleResultSelect: any,
    handleRadiusChange: any,
    handleUpdate:any,
    handleModalClose:any,
    searchResults: any
}

/**
 * CompaniesModal
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Companies Modal
 */
class CompaniesModal extends Component<Props> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Components.Wrapper
         * @return { String } JSX 
         */
        const {
            modal, 
            radius,
            handleSearchChange,
            handleResultSelect,
            handleRadiusChange,
            handleUpdate,
            handleModalClose,
            searchResults
        } = this.props;
      
        return ( 
            <Modal className="CompaniesModal" centered={false} open={modal}>
                <Modal.Content>
                    <Segment basic >
                        <Header size='medium'>
                            <Icon size='large' className='color-default' name='map marker alternate'/>
                            <Header.Content>Rechercher par adresse, commune ou code postal</Header.Content>
                        </Header>
                        <Search
                            size="large"
                            fluid
                            onSearchChange={handleSearchChange}
                            onResultSelect={(event, { result }) => handleResultSelect(event, result)}
                            results={searchResults}
                            minCharacters={3}
                            noResultsMessage="Aucun résultat"
                            input={{ icon: 'search', iconPosition: 'left' }}
                        />
                        <Header size='medium'>
                            <Icon size='large' className='color-default' name='r circle'/>
                            <Header.Content>{radius}km</Header.Content>
                        </Header>
                        <Slider 
                            value={radius} 
                            style={{ trackFill: { backgroundColor: "#235677"}}}  
                            settings={{
                                start: 1,
                                min: 1,
                                max: 30,
                                step: 1,
                                onChange: handleRadiusChange
                            }}
                        />
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        content='Annuler' 
                        onClick={handleModalClose}
                    />
                    <Button
                        onClick={handleUpdate}
                        className="btn default"
                        content='Modifier'
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}
export default CompaniesModal;