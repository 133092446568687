const articles =  [
    {   
        title: 'Lexique',
        icon: 'file alternate',
        contents: [
            [   
                {Icon : {name:"home", size:"big", circular:true }},
                {Header : {content:"IDCC", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"Identifiant de convention collective. Code unique attribué par le ministère du  travail permettant d’identifier une CCN. Le code IDCC est distinct du numéro de la brochure du Journal Officiel (JO) dans laquelle est publiée la CCN."}},
                {Container : {as:'p',content:"Il existe 1015 IDCC."}}
            ],
            [
                {Header : {content:"Code brochure", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"Numéro de la brochure Journal officiel du journal Officiel (JO) dans lequel est publiée la CCN. Numéro en quatre chiffres commençants par 3."}},
            ],
            [
                {Header : {content:"Code APE", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"Activité principale exercée. C’est un code délivré par l’INSEE à chaque entreprise française (code APEN) et à chaque établissement (Code APET). Il leur est délivré lors de leur inscription au système national d’identification et au répertoire des entreprises et de leurs établissements (répertoire SIRENE). Le code APE est composé de 5 caractères : 4 chiffres et une lettre."}},
                {Container : {as:'p',content:"Il caractérise l’activité principale d’une entreprise par référence au code NAF. Il est essentiellement utilisé à des fins statistiques."}},
                {Container : {as:'p',content:"En identifiant l’activité des entreprises, il permet de définir les textes réglementaires qu’elles doivent appliquer telles que les CCN."}}
            ],
            [
                {Header : {content:"Le code NAF", size:"large", textAlign:"center", className:"color-default"}},
                {Container : {as:'p',content:"Nomenclature d’activités françaises. C’est un code attribué par l’INSEE à chacun des secteurs d’activités économiques. Son but est de faciliter l’organisation de l’information économique et sociale. Il permet à l’INSEE d’octroyer à chaque entreprise française et à chacun de ses établissements un code APE."}},
                {Container : {as:'p',content:"Depuis 2008, le code NAF est constitué de 5 caractères : 4 chiffres et une lettre et compose 732 postes."}}
            ],
            [
                {Header : {content:"Une CCN", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"Est un accord écrit qui résulte de la négociation entre syndicats de salariés et d’employeurs. Elle traite des conditions d’emploi, de formation professionnelle et de travail des salariés et de leurs garanties sociales."}},
                {Container : {as:'p',content:"Les dispositions de la CCN peuvent être plus favorables pour le salarié que le code du travail. La CCN peut aussi contenir des dispositions que le Code du travail ne prévoit pas."}}
            ],
            [
                {Header : {content:"La DUE", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"Décision unilatérale de l’employeur est une des 3 voies citées par l’article L911-1 du code de la sécurité sociale pour mettre en place la complémentaire santé d’entreprise obligatoire."}},
                {Container : {as:'p',content:"Il est également possible de mettre en place une complémentaire santé d’entreprise par le biais d’une convention ou d’un accord collectif ou par un accord référendaire."}},
                {Container : {as:'p',content:"La DUE permet à l’employeur, tout en respectant les minima imposés par l’ANI du 11 janvier 2013, d’élaborer lui-même le contenu de la complémentaire santé de son entreprise. Il  n’a pas à négocier avec les syndicats de salariés. S’il existe un comité d’entreprise, l’employeur doit le consulter afin de l’informer de sa décision d’instaurer cette garantie pour les salariés. Pour être opposable, chaque salarié doit être informé par écrit de la DUE."}},
            ],
            [
                {Header : {content:"Un accord collectif", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"Est un accord conclu entre un employeur ou un groupement d’employeur et un ou plusieurs organisations syndicales en vue de fixer les règles relatives aux conditions de travail, à l’emploi ou aux garanties sociales des salariés. Il porte sur un ou plusieurs sujets spécifiques à la différence de la CCN qui traite dans son ensemble."}},
            ],
            [
                {Header : {content:"Le référendum", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"En entreprise : approbation d’accords d’entreprise, plan d’intéressement et de participation, plan d’épargne salariale, complémentaire santé, prévoyance ou retraite."}},
                {Container : {as:'p',content:"Accord de la majorité ou des 2/3 des électeurs inscrits."}},
                {Container : {as:'p',content:"Négociation annuelle sur le régime de prévoyance et régime de remboursement complémentaires de frais de santé (mutuelle d’entreprise)."}},
                {Container : {as:'p',content:"Une fois tous les 4 ans."}}
            ],
            [
                {Header : {content:"Organisme recommandé", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"Les représentants des branches professionnelles ont pu étudier voire même négocier les garanties lors d’un appel d’offres auprès de différents organismes assureurs et choisir l’offre estimée la plus pertinente pour la branche. "}},
                {Container : {as:'p',content:"La recommandation aux entreprises d’un ou plusieurs organismes de complémentaire santé doit se faire à l’issue d’une mise en concurrence loyale des organismes assureurs."}},
                {Container : {as:'p',content:"Elle n’a pas de caractère obligatoire."}}
            ],
            [
                {Header : {content:"Degré élevé de solidarité", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"Les branches professionnelles peuvent désormais recommander et non plus désigner un ou plusieurs organismes assureurs aux entreprises."}},
                {Container : {as:'p',content:"Pour qu’un accord collectif intègre cette possibilité, il faut que le contrat proposé présente un haut degré de solidarité dont les conditions sont prévues par le décret du 11 décembre 2014."}},
                {Container : {as:'p',content:"Le degré élevé de solidarité est ce qui caractérise un contrat de protection sociale complémentaire quand celui-ci propose notamment des prestations non directement contributives, des actions de prévention, des mesures d’aide sociale."}},
                {Container : {as:'p',content:"Inscrit à l’article L912-1 du Code de la sécurité sociale, ce principe est facultatif pour les branches professionnelles dans le cadre de la mise en place d’un accord collectif relatif à une complémentaire santé. Il est obligatoire quand la branche décide de recommander un ou plusieurs organismes assureurs aux entreprises."}},
                {Container : {as:'p',content:"Pour comporter un degré élevé de solidarité et être éligible à la recommandation, un accord de branche doit prévoir un financement d’au moins 2% des cotisations pour les prestations à caractère non directement contributif."}},
                {Container : {as:'p',content:"Cette participation peut financer :"}},
                {List : {as:'ul', bulleted:true, items:[
                    "Tout ou partie de la cotisation des salariés ou apprentis pouvant bénéficier d’une dispense d’adhésion,",
                    "Tout ou partie de la cotisation des salariés, apprentis ou anciens salariés quand elle est supérieure à 10% de leurs revenus bruts,",
                    "Des actions de prévention (risque professionnel ou santé publique)",
                    "Des prestations d’action sociale.c"
                ]}}
            ],
            [
                {Header : {content:"La procédure d’extension", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"Consiste à rendre applicable, par arrêté ministériel, une convention ou un accord collectif à tous les salariés et employeurs compris dans son champ d’application. La convention ou l’accord étendu s’applique ainsi aux entreprises qui ne sont pas adhérentes à l’une des organisations signataires. L’arrêté d’extension est publié au Journal officiel."}},
                {Container : {as:'p',content:"Cette procédure est engagée à la demande de l’une des parties signataires ou du Ministère du travail."}}
            ],
            [
                {Header : {content:"Avis d’extension", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"La demande d’extension d’un accord ou d’un avenant est transmise à la commission nationale de la négociation collective (CNNC) qui donne son avis pour étendre à toutes les entreprise l’accord ou l’avenant. "}}
            ],
            [
                {Header : {content:"Arrêté d’extension", size:"large", textAlign:"center", className:"color-default" }},
                {Container : {as:'p',content:"Suivant l’avis de la CNNC, le Ministre du travail publie un avis relatif à l’extension qui permet aux organisations non signataires de faire opposition au texte signé sous 15 jours. A l’issue de cette période et sans opposition signifiée, le Ministre prend un arrêté d’extension qui permet l’application du texte conventionnel à tout le secteur concerné."}}
            ]
        ]
    },
    {
        title: 'Les risques pour l’employeur',
        icon: 'shield',
        contents: [
        ]
    },
    {
        title: 'Le formalisme des régimes collectifs',
        icon: 'book',
        contents: [
        ]
    }
]

export { articles } ;

