// @flow
import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Grid, Header, Transition, Icon } from 'semantic-ui-react';
import { Wrapper } from "components";
import { articles } from 'constantes';

import './Articles.scss';

type Props = {}

type State = {
    visible: boolean
}

/**
 * Articles
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Articles container
 */
class Articles extends Component<Props,State> {

    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            visible: false
        }
    }
    
    componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Articles
         * @method componentDidMount
         * @return { Void } 
         */
        this.setState({  visible: true });
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Articles
         * @return { String } JSX 
         */
        const { visible } = this.state
        return(
            <Grid className="Articles page bg-gradient">
                 <Grid.Row>      
                    <Grid.Column>
                        <Transition visible={visible} animation="fade up" duration={500}>
                            <Header as='h2' textAlign='center' className='lead'>
                                Pour aller plus loin, <br/>lisez les nos articles 
                            </Header>
                        </Transition>
                        {articles.map((article, index) => {
                            return (
                                <Fragment key={index} >
                                    { article.contents.length ? 
                                     <Link key={index} className="link medium center" to={{
                                        pathname: 'article',
                                        state: index
                                    }}>
                                        <Icon circular  name={article.icon}/>
                                        <Wrapper name="link-content">{article.title}</Wrapper>
                                    </Link>   
                                    : null }
                                </Fragment>
                            )
                        })}
                    </Grid.Column>
                </Grid.Row>      
            </Grid>
        )
    }
}

export { Articles }