const messagesFinder = {
    400: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
};

const messagesCcn = {
    400: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
    403: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
};

const messagesSignIn = {
    400: "Vous avez oublié quelque chose ! On ne peut donc pas vous connecter. 🙃",
    403: "Vous n'êtes pas autorisé à accéder à cette application",
    404: "Vous faites erreur, merci de vérifier votre adresse mail et votre mot de passe. 😇",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
};

const messagesCompanies = {
    1: "Vérifier que votre navigateur ou/ et système autorise la géolocalisation.",
    404: "Aucun établissement trouvé",
    502: "Ernest se repose, il n'est pas disponible pour le moment ! Revenez un peu plus tard, merci 😊",
    504: "Nombre d'entreprises trop important, veuillez modifier votre position (adresse ou rayon) ou contactez-nous pour une extraction personnalisée ",
};

export { messagesFinder, messagesCcn, messagesSignIn, messagesCompanies };
