// @flow
import { LocalStorage } from './storageService';
import { checkStatus, checkError } from 'tools';
/**
 * Ccn service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description Ccn service
 */
class Ccn {
    
    static url:any = process.env.REACT_APP_CCN_API
    static path:any = process.env.REACT_APP_CCN_API_PATH_CCN
    
    static entrypoints:any = {
        ccns: `${this.path}`,
        search: `${this.path}/search`
    }

    static search = async (query:string = '') => {
        /**
         * Search
         *
         * @async
         * @method search
         * @memberof Services.Ccn
         * @param { String } query query to search
         * @return { Object } CCNS
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const fields = 'title,ref,idcc,description,brochure,ape,general_title';
            const response = await fetch(`${this.url}/${this.entrypoints.search}?${query}fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);

        } catch(error) {
            throw checkError(error);
        }
    }

    static get = async (id:string) => {
        /**
         * Get
         *
         * @async
         * @method get
         * @memberof Services.Ccn
         * @param { String } ref ref to get
         * @return { Object } CCN
        */
        try {
            const token = await LocalStorage.getItem('token')  || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.ccns}/${id}`, {
            method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
             });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }
}
export  { Ccn };