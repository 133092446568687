// @flow
import React from 'react';
import { ChatBot } from 'services/chatBotService';
import { SessionStorage } from 'services/storageService';
const ChatBotContext = React.createContext<any>();

type Props = {
    children: any
}

type State = {};

/**
 * ChatBotProvider Provider
 * @author Tomasz tpr@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description ChatBot provider
 */
class ChatBotProvider extends React.Component<Props,State>  {

    messaging = async (type: string, message: any) => {
        /**
         * @instance
         * @async
         * @method messaging
         * @memberof Providers.ChatBotProvider
         * @param { String } type Message type
         * @param { Object } message Message content
         * @return { Object } Bot response
         */
        try {
            const response = await ChatBot.messaging(type, message);
            const payload = await response.json();
            return payload
        }
        catch(error) {
            throw error
        }
    }

    getCache = async () => {
        /**
         * @instance
         * @async
         * @method getCache
         * @memberof Providers.ChatBotProvider
         * @return { Array } Ccn cache result
         */
        try {
            return await SessionStorage.getItemAsJSON('cb_cache');
        }
        catch(error) {
            throw error
        }
    }

    setCache = async (data: any) => {
        /**
         * @instance
         * @async
         * @method setCache
         * @memberof Providers.ChatBotProvider
         * @return { Void }
         */
        try {
            await SessionStorage.setItemAsJSON('cb_cache', data);
        }
        catch(error) {
            throw error
        }
    }

    clearCache = async () => {
        /**
         * @instance
         * @async
         * @method clearCache
         * @memberof Providers.ChatBotProvider
         * @return { Void }
         */
        try {
            await SessionStorage.removeItem('cb_cache');
        }
        catch(error) {
            throw error
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.ChatBotProvider
         * @return { String } JSX 
         */
        return (
            <ChatBotContext.Provider 
                value={{
                    messaging: this.messaging,
                    getCache: this.getCache,
                    setCache: this.setCache,
                    clearCache: this.clearCache
                }}>
                {this.props.children}
            </ChatBotContext.Provider>
        )
    }
}

const ChatBotConsumer = ChatBotContext.Consumer

export { ChatBotProvider, ChatBotConsumer }