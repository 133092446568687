// @flow
import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
    ConnectionProvider,
    CcnProvider,
    CompaniesProvider,
    ChatBotProvider,
} from "providers";
import { ConnectionConsumer } from "providers";
import { PublicRoutes, PrivateRoutes } from "routes";
import { Theme, Header, Footer } from "components";
import Provider from "Provider";
import "./App.scss";

/**
 * App
 * @author Tomasz tpr@deltacode.fr
 * @memberof App
 * @extends {React.Component}
 * @description App Component
 */
class App extends Component<{}> {
    render() {
        /**
         * @instance
         * @method render
         * @memberof App.App
         * @return { String } JSX
         */
        return (
            <ConnectionProvider>
                <ConnectionConsumer>
                    {(props) => (
                        <Router>
                            {props.authenticated ? (
                                <Provider
                                    providers={[
                                        CcnProvider,
                                        CompaniesProvider,
                                        ChatBotProvider,
                                    ]}
                                >
                                    <Theme />
                                    {props.header ? <Header /> : null}
                                    <PrivateRoutes {...props} />
                                    {props.footer ? <Footer /> : null}
                                </Provider>
                            ) : (
                                <PublicRoutes />
                            )}
                        </Router>
                    )}
                </ConnectionConsumer>
            </ConnectionProvider>
        );
    }
}

export default App;
