// @flow
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { LocalStorage } from "services";
import {
    Grid,
    Header,
    Icon,
    Button,
    Segment,
    Message,
    Container,
} from "semantic-ui-react";
import { Helper, Loading } from "components";
import { messagesCcn } from "constantes";
import { capitalize, isEmptyObject, setMessage } from "tools";

import "./Ccn.scss";

type Props = {
    consumers: any,
    location: any,
    match: any,
    history: any,
};

type State = {
    id: string,
    loading: boolean,
    error: boolean,
    ccn: any,
    authorizedParts: Array<string>,
    message: any,
};

/**
 * Ccn Container
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Ccn container
 */
class Ccn extends Component<Props, State> {
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            id: "",
            ccn: {},
            authorizedParts: [],
            message: {},
        };
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Ccn
         * @method componentDidMount
         * @return { Void }
         */
        const authorizedParts = await LocalStorage.getItem("authorizedParts");

        await this.setState({
            authorizedParts: authorizedParts,
        });
        this.getCcn();
    }

    getCcn = async () => {
        const { id } = this.props.match.params;
        const { ccns } = this.props.consumers;

        const localCcn = await ccns.getLocalCcn();

        try {
            if (localCcn && localCcn.ref === id) {
                await this.setState({
                    ccn: localCcn,
                });
            } else {
                await this.setState({
                    loading: true,
                });
                const ccn = await ccns.getCcn(id);
                if (ccn) {
                    await ccns.setLocalCcn(ccn);
                    await this.setState({
                        ccn: ccn,
                    });
                }
                await this.setState({
                    loading: false,
                });
            }
        } catch (error) {
            this.setState({
                error: true,
                message: setMessage(messagesCcn, error.status),
            });
        }
    };

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Ccn
         * @return { String } JSX
         */
        const { loading, error, ccn, message, authorizedParts } = this.state;
        const { id } = this.props.match.params;
        return (
            <Fragment>
                <Grid columns={1} className="Ccn page">
                    <Grid.Row>
                        <Grid.Column>
                            <Segment basic>
                                {!isEmptyObject(message) && (
                                    <Message
                                        color={message.color}
                                        content={message.text}
                                    />
                                )}
                            </Segment>
                            <Helper />
                            <Header as="h1" textAlign="center" color="grey">
                                IDCC {ccn.idcc} Brochure {ccn.brochure}
                            </Header>
                            {!isEmptyObject(ccn) && !loading && !error ? (
                                <Fragment>
                                    <Header as="h2" textAlign="center">
                                        {capitalize(ccn.description)}
                                    </Header>
                                    {ccn.attachment && (
                                        <Header as="h4" textAlign="center">
                                            {capitalize(ccn.attachment)}
                                        </Header>
                                    )}
                                    {ccn.redirectTo && ccn.redirectTo !== "" && (
                                        <Container textAlign="center">
                                            <Link
                                                className="ui basic button"
                                                to={{
                                                    state: {
                                                        idcc:
                                                            ccn.redirectTo.idcc,
                                                        id: ccn.redirectTo.ref,
                                                        scope: "ccn",
                                                    },
                                                    pathname: `/ccn/${ccn.redirectTo.ref}`,
                                                }}
                                            >
                                                Redirection vers IDCC{" "}
                                                {ccn.redirectTo.idcc}
                                            </Link>
                                        </Container>
                                    )}
                                    {ccn.redirectFrom &&
                                        ccn.redirectFrom[0] !== "" && (
                                            <Container
                                                style={{
                                                    marginTop: ccn.redirectTo
                                                        ? "0.5em"
                                                        : 0,
                                                }}
                                                textAlign="center"
                                            >
                                                Redirection depuis IDCC :{" "}
                                                {ccn.redirectFrom
                                                    .map(({ idcc }) => idcc)
                                                    .join(", ")}
                                            </Container>
                                        )}
                                    {Object.keys(ccn._structure).map(
                                        (disposition, index) => {
                                            const element =
                                                ccn._structure[disposition];

                                            return (
                                                <Fragment key={index}>
                                                    <Header
                                                        as="h3"
                                                        content={element.title}
                                                        className="color-default"
                                                    />
                                                    {Object.keys(
                                                        element["colleges"]
                                                    ).map((college, index) => {
                                                        let color = "green";
                                                        let condition = false;
                                                        if (
                                                            ccn.dispositions &&
                                                            ccn.dispositions[
                                                                disposition
                                                            ]
                                                        ) {
                                                            if (
                                                                ccn
                                                                    .dispositions[
                                                                    disposition
                                                                ][college]
                                                            ) {
                                                                color =
                                                                    "yellow";
                                                                condition = true;
                                                                if (
                                                                    ccn
                                                                        .dispositions[
                                                                        disposition
                                                                    ][college][
                                                                        "assureurs"
                                                                    ]
                                                                ) {
                                                                    color =
                                                                        "orange";
                                                                }
                                                                if (
                                                                    ccn._metadata &&
                                                                    ccn
                                                                        ._metadata
                                                                        .dispositions[
                                                                        disposition
                                                                    ] &&
                                                                    ccn
                                                                        ._metadata
                                                                        .dispositions[
                                                                        disposition
                                                                    ][college]
                                                                        .unusable
                                                                ) {
                                                                    color =
                                                                        "red";
                                                                }
                                                            }
                                                        } else {
                                                            if (
                                                                ccn._metadata &&
                                                                ccn._metadata
                                                                    .dispositions[
                                                                    disposition
                                                                ] &&
                                                                ccn._metadata
                                                                    .dispositions[
                                                                    disposition
                                                                ][college]
                                                                    .unusable
                                                            ) {
                                                                color = "red";
                                                            }
                                                        }
                                                        return (
                                                            <Link
                                                                key={index}
                                                                className="link medium"
                                                                data-condition={
                                                                    condition
                                                                        ? "inactive"
                                                                        : "active"
                                                                }
                                                                to={{
                                                                    pathname: `/ccn/${id}/detail`,
                                                                    state: {
                                                                        title:
                                                                            ccn.title,
                                                                        description:
                                                                            ccn.description,
                                                                        disposition: disposition,
                                                                        college: college,
                                                                        idcc:
                                                                            ccn.idcc,
                                                                        id: id,
                                                                        scope:
                                                                            "ccn",
                                                                    },
                                                                }}
                                                            >
                                                                <Icon
                                                                    name="circle"
                                                                    color={
                                                                        color
                                                                    }
                                                                />
                                                                {
                                                                    element[
                                                                        "colleges"
                                                                    ][college]
                                                                }
                                                                <Icon
                                                                    name="chevron right"
                                                                    color="grey"
                                                                />
                                                            </Link>
                                                        );
                                                    })}
                                                </Fragment>
                                            );
                                        }
                                    )}
                                    <Segment basic textAlign="center">
                                        {authorizedParts.includes(
                                            "entreprises"
                                        ) && (
                                            <Link
                                                to={{
                                                    pathname: `/entreprises/${id}`,
                                                    state: {
                                                        id: id,
                                                        idcc: ccn.idcc,
                                                        title: ccn.title,
                                                        description:
                                                            ccn.description,
                                                        codes: ccn.ape,
                                                    },
                                                }}
                                            >
                                                <Button
                                                    className="btn default"
                                                    size="large"
                                                >
                                                    <Button.Content>
                                                        Voir les entreprises
                                                    </Button.Content>
                                                </Button>
                                            </Link>
                                        )}
                                    </Segment>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {!error && <Loading title={`Chargement`} />}{" "}
                                </Fragment>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Fragment>
        );
    }
}

export { Ccn };
