// @flow
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Header, Segment, Container, Pagination, Menu, Icon, Message, Button, Checkbox, Popup, Label } from 'semantic-ui-react';
import { Wrapper, Loading, XlsxItem, Knowledge, KeyFigures } from 'components';
import { messagesCompanies } from "constantes";
import CompaniesModal from './CompaniesModal';
import { isEmptyObject, isEmptyEveryObjects, numberFormat, capitalize, setMessage, getKeyFiguresYears, formatAddress } from 'tools';

import './Companies.scss';

type Props = {
    consumers: any,
    location:any,
    history: any,
    match: any
};

type State = {
    loading: boolean,
    visible: boolean,
    error: boolean,
    extend: boolean,
    extendedDiabled: boolean,
    errorStatus : any,
    modal: boolean,
    idcc: number,
    description: string,
    total: number,
    range: number,
    totalResults: number,
    maxResults: number,
    totalPages: number,
    activePage: number,
    companies: any,
    position: any,
    searchPosition: any,
    message: any,
    radius: number,
    searchResults: any,
    loadingKnowledge: boolean,
    currentSiret: any
};

const paths = {
    redirect : "/recherche/entreprises"
}

const paginateRange = 10;

/**
 * Companies
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Companies container
 */

class Companies extends Component<Props, State> {

    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            visible: false,
            modal: false,
            error: false,
            errorStatus: '',
            extend: false,
            extendedDiabled: true,
            idcc : 0,
            description: '',
            totalPages: 0,
            activePage: 1,
            totalResults: 0,
            maxResults: 1000,
            total: 0,
            range: 1,
            companies: [],
            position: {},
            message: {},
            radius: 1,
            searchPosition: {},
            searchResults: [],
            currentSiret: null,
            loadingKnowledge: false,
        };
    }

    async componentDidMount(){
        /**
         * @instance
         * @async
         * @memberof Containers.Companies
         * @method componentDidMount
         * @return { Void } 
         */  
        if(!this.props.location.state || !this.props.location.state.idcc){
            this.props.history.push(paths.redirect)
        }else{
            try {     
                const {  companies, ccns } = this.props.consumers;
                const { id, idcc, description } = this.props.location.state;
                const position = await companies.getPosition();  
                const radius = await companies.getRadius();  
                const { page, extend } = await companies.getParams();   
             
                let ccn;
                if(this.props.history.prev){
                    await this.setState({
                        activePage: page,
                    });
                    ccn = ccns.getLocalCcn();
                }else{
                    ccn = await ccns.getCcn(id);
                    await ccns.setLocalCcn(ccn);
                    await companies.clear(['companies']);
                    await companies.setParams({ page: 1, range: 1, current: 0, total:0 }); 
                    
                } 
                await this.setState({
                    idcc: idcc,
                    extend: extend,
                    position: position ? position : this.state.position,
                    radius: radius ? radius/1000 : this.state.radius,
                    description: description,
                    error: false
                });
                await this.getCompanies(extend);

            }
            catch(error) {
                const status = error.code ? error.code : error.status;
                this.setState({ 
                    loading: true,
                    error: true,
                    message: setMessage(messagesCompanies, status)
                });
            }
        }
    }

    pagiante = (etablissements: any, page: number) => {
        /**
         * @instance
         * @async
         * @method pagiante
         * @memberof Containers.Companies
         * @description Pagiante results
         * @param { Array } etablissements Etablissements
         * @param { Numbr } page Current page
         * @return { Array } 
         */
        return etablissements.slice((page - 1) * paginateRange, page * paginateRange);
    }

    getCompanies = async (extended:boolean) => {
        /**
         * @instance
         * @async
         * @method getCompanies
         * @memberof Containers.Companies
         * @description Get companies
         * @return { Void } 
         */
        const { companies } = this.props.consumers;
      
        const position = await companies.getPosition();
        const radius = await companies.getRadius();
        try{
            let etablissements;
            if(this.props.history.prev ){
                etablissements = await companies.getLocalCompanies();
                delete this.props.history.prev
            }else{
                etablissements = await companies.getCompanies(extended)
            }
            const { total, page } = await companies.getParams();
            await this.setState({
                loading: true,
                position: position,
                radius:radius/1000,
                companies: this.pagiante(etablissements, page ? page : 1),
                total:total,
                extendedDiabled: (total> 1000) ? true : false,
                totalResults: etablissements.length,
                totalPages: Math.ceil(etablissements.length/paginateRange) > Math.ceil(total/paginateRange) ? Math.ceil(total/paginateRange) : Math.ceil(etablissements.length/paginateRange),
                message:{}
            });
        } catch(error) {
            
            const status = error.code ? error.code : error.status;
            this.setState({ 
                loading: true,
                error: true,
                position: position,
                companies:[],
                total:0,
                totalResults: 0,
                message: setMessage(messagesCompanies, status)
            });
        }
    }

    handleExtended = async (event:SyntheticInputEvent<>, {checked}:any) =>{
        /**
         * @instance
         * @async
         * @method handleExtended
         * @memberof Containers.Companies
         * @description Extend results with APE code
         * @param { Number } value Radius
         * @return { Void } 
         */
        const { companies } = this.props.consumers;
        await companies.clear(['companies']);
        await this.setState({
            loading: false,
            extend: checked,
            total:0,
            activePage:1,
        });

        await companies.setParams({
            extend: checked,
            page: 1,
            range:1,
        });
        await this.getCompanies(checked);
    }

    handlePageChange = async (event: any, data: any=null) =>{
        /**
         * @instance
         * @async
         * @method handlePageChange
         * @memberof Containers.Companies
         * @description Change page
         * @params { Object } event Event
         * @params { Number } activePage Number of page
         * @return { Void } 
         */
        const { companies } = this.props.consumers;
        const { total, current, range, extend } =  await companies.getParams();
     
        if((data.totalPages * paginateRange) !== this.state.maxResults){
            if(data.activePage === data.totalPages && current < total){
  
                await companies.setParams({ range: range + 1});
                await this.setState({loading: false}); 
                await this.getCompanies(extend);
            }

            const etablissements =  await companies.getLocalCompanies();
            await companies.setParams({ page :data.activePage});
            await this.setState({
                activePage: data.activePage,
                companies:  this.pagiante(etablissements, data.activePage),
                totalPages: Math.ceil(etablissements.length/paginateRange) > Math.ceil(total/paginateRange) ? Math.ceil(total/paginateRange) : Math.ceil(etablissements.length/paginateRange)
            });

        }else {
            const etablissements =  await companies.getLocalCompanies();
            await companies.setParams({ page :data.activePage});
            await this.setState({  companies:  this.pagiante(etablissements, data.activePage), activePage: data.activePage})
        
        }
    }

    handleClear = async () => {
        /**
         * @instance
         * @async
         * @method initApes
         * @memberof Containers.Ape
         * @description Remove apes and user position
         * @return { Void } 
         */
        const { companies } = this.props.consumers;
        const { extend } =  await companies.getParams();
        await companies.clear(['position', 'companies']);
        await companies.setParams({ range :1, page:1});
        await this.setState({ loading: false});
        await this.getCompanies(extend);
    }

    handleUpdate = async () => {
        /**
         * @instance
         * @async
         * @method handleUpdate
         * @memberof Containers.Ape
         * @description Remove apes, update position and radius
         * @return { Void } 
         */
        const { companies } = this.props.consumers;
        const {extend } =  await companies.getParams();
        if(!isEmptyObject(this.state.searchPosition)){
            await companies.setPosition(this.state.searchPosition);
        }
        await companies.clear(['companies']);
        await companies.setParams({ page: 1, range :1 });
        await this.setState({ loading: false, modal: false, activePage:1 });
        await this.getCompanies(extend);
    }
    
    handleSearchChange = async (event:SyntheticInputEvent<>, { value }:any) => {
        /**
         * @instance
         * @async
         * @method handleSearchChange
         * @memberof Containers.Companies
         * @description Search user position
         * @return { Void } 
         */
        if(value.length > 2){
            const { companies } = this.props.consumers;
            const response = await companies.findPosition(value);   
            const results = response.map((item)=>{
                item['title'] = item.formatted_address
                item['partial_match'] = ''
                return item
            });
            this.setState({
                searchResults: results
            })
        }
    }

    handleSearchKnowledge = (index: any, item: any) => async (event: any) => {
         /**
         * @instance
         * @async
         * @method handleSearchKnowledge
         * @memberof Containers.Companies
         * @description Search company data
         * @return { Void } 
         */
        event.preventDefault();
        if(!item.knowledge){
            try{
                this.setState({ 
                    currentSiret:item.siret,
                    loadingKnowledge: true,
                });
                const { companies } = this.props.consumers;
                const localCompanies = await companies.getLocalCompanies();
                const etablissement = item.uniteLegale.denomination ? item.uniteLegale.denomination : `${item.uniteLegale.nom} ${item.uniteLegale.prenom1}`
                const response = await companies.getKnowledge(etablissement, formatAddress(item.adresse), item.siret);  
                this.setState({ 
                    loadingKnowledge: false,
                    companies: companies.updateCompanies(this.state.companies, item.siret, response)
                });
                await companies.setLocalCompanies(companies.updateCompanies(localCompanies,item.siret, response));
            } catch(error) {
            }
        }
    }

    handleResultSelect = async (event:SyntheticInputEvent<>, value:any) => {
        /**
         * @instance
         * @async
         * @method handleResultSelect
         * @memberof Containers.Companies
         * @description Update state user position
         * @return { Void } 
         */
        this.setState({ 
            searchPosition: {
                adresse: value.formatted_address,
                coords: {
                    latitude: value.geometry.location.lat,
                    longitude: value.geometry.location.lng
                }
            } 
        });
    }

    handleRadiusChange = async (value: any) => {
        /**
         * @instance
         * @async
         * @method handleRadiusChange
         * @memberof Containers.Companies
         * @description Update radius value
         * @param { Number } value Radius
         * @return { Void } 
         */
        const { companies } = this.props.consumers;
         await companies.setRadius(value);
        this.setState({ radius: value });
    }

    handleModalOpen = () => {
        /**
         * @instance
         * @async
         * @method handleModalOpen
         * @memberof Containers.Companies
         * @description Open confirmation modal
         * @return { Void } 
         */
        this.setState({ modal: true });
    }

    handleModalClose = () => {
        /**
         * @instance
         * @async
         * @method handleModalClose
         * @memberof Containers.Companies
         * @description Close confirmation modal
         * @return { Void } 
         */
        this.setState({ modal: false });
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Companies
         * @return { String } JSX 
         */
        const { 
            loading,
            total,
            totalResults,
            idcc, 
            extend,
            extendedDiabled,
            description, 
            companies, 
            activePage,
            totalPages,
            maxResults,
            position,
            radius,
            modal,
            message,
            errorStatus,
            searchResults,
            loadingKnowledge,
            currentSiret
        } = this.state;
        
        return(
            <Grid className="Companies page">
               <CompaniesModal 
                    handleRadiusChange={this.handleRadiusChange}
                    handleSearchChange={this.handleSearchChange}
                    handleResultSelect={this.handleResultSelect}
                    handleUpdate={this.handleUpdate}
                    handleModalClose={this.handleModalClose}
                    searchResults={searchResults}
                    radius={radius}
                    extend={extend}
                    total={total}
                    modal={modal}
               />                         
                <Grid.Row>
                    <Grid.Column>
                        <Segment basic>
                            { <Header as='h1' textAlign='center' color='grey'>{idcc ?  `CCN ${idcc}` : ''} </Header>}
                            <Header as='h2' textAlign='center'>{ capitalize(description) }</Header>
                        </Segment>
                        <Menu secondary stackable> 
                            <Menu.Item header as='h4'>
                                <Icon  size='large' className={loading ? 'color-default' : 'color-grey-light'} name='map marker alternate'/>
                                <div className={loading ? 'color-default' : 'color-grey-light'}>{position.adresse}</div>  
                                <Icon size='large' className={loading ? 'color-default' : 'color-grey-light'} name='r circle'/>
                                <div className={loading ? 'color-default' : 'color-grey-light'}>{radius}km</div>
                            </Menu.Item>
                            <Menu.Menu position='right'>
                                <Menu.Item onClick={this.handleClear} disabled={errorStatus === "companies: 1" || !loading}> Mettre à jour ma position </Menu.Item>  
                                <Menu.Item disabled={!loading} onClick={this.handleModalOpen}> Modifier ma position </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                        <Menu secondary stackable>
                            <Menu.Item  disabled={!loading || extendedDiabled }>
                                <Checkbox disabled={extendedDiabled} checked={this.state.extend && !extendedDiabled } onChange={this.handleExtended} style={{ marginRight: "1em"}} /> Rechercher par code APE
                            </Menu.Item>   
                            <Menu.Item> {extend && loading &&  <Fragment ><Label circular color="green" size="tiny"/>IDCC confirmé</Fragment>} </Menu.Item>
                            <Menu.Menu position='right'>
                                <XlsxItem disabled={!loading} consumers={this.props.consumers}>
                                    <Icon name='download'/> Télécharger au format Excel {loading && totalResults ? `(${totalResults < total ? totalResults :total } résultats)` : ''} 
                                </XlsxItem>
                            </Menu.Menu>
                        </Menu>
                        {loading ? (
                            <Fragment>
                            { !isEmptyObject(message) && <Message color={message.color} content={message.text}/> }
                                <Wrapper> Nombre de résultats à télécharger : <strong>{total > 200 ? `${totalResults > total ? total : totalResults} / ${ total < maxResults ? total : maxResults}` : total} résultats</strong>  <span style={{ marginLeft: '0.5em'}}>{ total > maxResults && `(sur ${total} établissements correspondant à votre recherche)`}</span></Wrapper>            
                                {companies.length ?
                                    <Fragment>
                                        {companies.map((item, index ) => {
                                            const title= item.etablissement.classe;
                                            return(
                                                <Link key={ index } 
                                                    className="link small"  
                                                    to={{pathname:`/entreprise/${item.siret}`,
                                                    state: { 
                                                        idcc: idcc,
                                                        id: item.siret,
                                                        title: item.uniteLegale.denomination ? item.uniteLegale.denomination : item.uniteLegale.identite,
                                                        description: title,
                                                        scope: 'entreprise'
                                                    } 
                                                }}>
                                                    {item.uniteLegale.denomination || item.uniteLegale.nom ? item.uniteLegale.denomination ? item.uniteLegale.denomination :  `${item.uniteLegale.nom} ${item.uniteLegale.prenom1}` : `${item.siret}  (Diffusion partielle)`}
                                                    <Grid stackable verticalAlign='middle'>
                                                        <Grid.Row >
                                                            <Grid.Column width={12}>
                                                                <Wrapper name="content">
                                                                { extend && item.ccn && item.ccn.find(item => item.idcc === idcc) && <Label circular color="green" size="tiny"/>}
                                                                    Activité principale: { item.etablissement.classe }
                                                                    <Wrapper name="workforce">( { item.uniteLegale.trancheEffectifsLabel ? item.uniteLegale.trancheEffectifsLabel : 'Effectifs inconnus'} )</Wrapper> 
                                                                </Wrapper>   
                                                                <Wrapper name="keyFigures"> Chiffre d'affaires {getKeyFiguresYears().n2} : { item.keyFigures  && item.keyFigures[`chiffre${getKeyFiguresYears().n2}`] ? numberFormat(item.keyFigures[`chiffre${getKeyFiguresYears().n2}`].ca)  :'inconnu'}  
                                                                {  isEmptyEveryObjects([`chiffre${getKeyFiguresYears().n2}`,`chiffre${getKeyFiguresYears().n3}`,`chiffre${getKeyFiguresYears().n4}`],item.keyFigures) ?
                                                                <KeyFigures company={item}/>
                                                            : null}</Wrapper> 
                                                            </Grid.Column>
                                                            <Grid.Column className="knowledge-icons" textAlign="right" width={3}>
                                                                {item.knowledge &&
                                                                <Fragment>
                                                                    <Icon color="grey" flipped="horizontally" disabled={!item.knowledge.phone} name='phone'/>
                                                                    <Icon color="grey" disabled={!item.knowledge.emails || !item.knowledge.emails.length} name='mail' />
                                                                    <Icon color="grey" disabled={!item.knowledge.website} name='linkify' />
                                                                    <Icon color="grey" disabled={!item.knowledge.socials || !item.knowledge.socials.length} name='group' />
                                                                    </Fragment>
                                                                }
                                                            </Grid.Column>
                                                            <Grid.Column textAlign="left"  columns={1} >
                                                                {loadingKnowledge && item.siret === currentSiret 
                                                                    ? <Button basic icon> <Icon color="grey" loading name="spinner"/></Button>
                                                                    : item.knowledge 
                                                                        ? <Popup 
                                                                            hoverable 
                                                                            position='left center' 
                                                                            content={<Knowledge data={item} address />} 
                                                                            trigger={<Button icon="address card"/>} 
                                                                        /> 
                                                                        : <Button 
                                                                            basic 
                                                                            icon 
                                                                            active={item.knowledge ? true: false}  
                                                                            disabled={loadingKnowledge  && item.siret !== currentSiret} 
                                                                            onClick={this.handleSearchKnowledge(index, item)}>
                                                                                <Icon name="search"/>
                                                                        </Button>
                                                                }
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Link>
                                            )
                                        })}
                                            <Container textAlign='center'>
                                                <Pagination
                                                    //siblingRange= {range}
                                                    onPageChange = {this.handlePageChange}
                                                    activePage={activePage}
                                                    totalPages={totalPages}
                                                />
                                            </Container>
                                        </Fragment>
                                  : null }
                            </Fragment>
                        ) : (
                            <Loading title='Chargement'/>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export { Companies }