const strings = {
    history: {
        ccn: {
            title : " Rechercher une CCN",
            description : " Pour trouver une CCN en un clin d’oeil, entrez simplement un code APE, un IDCC, un code Brochure ou un Intitulé."
        },
        entreprises: {
            title : "Trouvez des entreprises par CCN",
            description : "Vous avez juste à saisir la CCN souhaitée et j'irai chercher pour vous les entreprises à prospecter."
        }
    },
    favorites : {
        ccn: "CCN",
        entreprise: "Entreprises"
    }
}


export { strings }
