// @flow
import React, { Component, Fragment } from "react";
import { Table, List } from "semantic-ui-react";

import "./WidgetTable.scss";

type Props = {
    content: any,
    data: any,
};

type State = {};

/**
 * WidgetTable widget
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description Widget table
 * @return {ReactComponent} WidgetTable
 */
class WidgetTable extends Component<Props, State> {
    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetTable
         * @return { String } JSX
         */
        const { content, data } = this.props;
        const rows = data[content.body];

        return (
            <Fragment>
                {rows && rows.length ? (
                    <Table unstackable>
                        <Table.Header>
                            <Table.Row>
                                {content.headers.map((item, index) => {
                                    return (
                                        <Table.HeaderCell key={index}>
                                            {item}
                                        </Table.HeaderCell>
                                    );
                                })}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {rows.map((item, index) => {
                                return (
                                    <Table.Row key={index}>
                                        {Object.keys(item).map(
                                            (element, index) => {
                                                return (
                                                    <Table.Cell key={index}>
                                                        {Array.isArray(
                                                            item[element]
                                                        ) ? (
                                                            <List
                                                                items={
                                                                    item[
                                                                        element
                                                                    ]
                                                                }
                                                            />
                                                        ) : (
                                                            <Fragment>
                                                                {item[element]}
                                                            </Fragment>
                                                        )}
                                                    </Table.Cell>
                                                );
                                            }
                                        )}
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                ) : null}
            </Fragment>
        );
    }
}

export { WidgetTable };
