// @flow
import React, { Component } from "react";

type Props = {}

/**
 * Empty
 * @author Hugo hle@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Empty container
 */

class Empty extends Component<Props> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Empty
         * @return { String } JSX 
        */
        return <></>
    }
}

export { Empty };