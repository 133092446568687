// @flow
import React, { Component, Fragment } from "react";
import {
    Grid,
    Segment,
    Header,
    Divider,
    Transition,
    Button,
    Icon,
    Table,
    List,
} from "semantic-ui-react";
import { resolvePath } from "tools";
import * as widgets from "widgets";

import "./WidgetBlock.scss";

type Props = {
    widget: any,
    data: any,
};

type State = {
    visible: boolean,
};

/**
 * WidgetBlock
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description Main block contains the other widgets
 */
class WidgetBlock extends Component<Props, State> {
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props);
        const visible = this.props.widget ? !this.props.widget.hidden : false;
        this.state = {
            visible: visible,
        };
    }

    componentDidUpdate(previousProps: any, previousState: any) {
        /**
         * @instance
         * @method componentDidUpdate
         * @memberof Widgets.WidgetBlock
         * @return { Void }
         */
        if (this.props.widget.hidden !== previousProps.widget.hidden) {
            this.setState({ visible: !this.props.widget.hidden });
        }
    }

    toggleVisibility = () => {
        /**
         * @instance
         * @method toggleVisibility
         * @memberof Widgets.WidgetBlock
         * @description Change hidden status
         * @return { Void }
         */
        this.setState((prevState) => ({ visible: !prevState.visible }));
    };

    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetBlock
         * @return { String } JSX
         */
        const visible = this.state.visible;
        const { data, widget } = this.props;
        const title =
            widget && widget.title ? resolvePath(data, widget.title) : "";
        const comments =
            widget && widget.comments ? resolvePath(data, widget.comments) : "";
        const details =
            widget && widget.details ? resolvePath(data, widget.details) : "";
        return (
            <Fragment>
                {data && widget && (
                    <Segment className="Widget-block">
                        {widget.content.length || comments ? (
                            <Header
                                className="title"
                                as="h4"
                                floated="left"
                                content={title}
                            />
                        ) : (
                            <Header
                                className="title center"
                                as="h4"
                                textAlign="center"
                                content={title}
                            />
                        )}
                        {widget.hidden && (
                            <Button
                                content={
                                    <Icon
                                        circular
                                        name={visible ? "minus" : "plus"}
                                    />
                                }
                                onClick={this.toggleVisibility}
                            />
                        )}
                        <Transition
                            visible={visible}
                            animation="fade"
                            duration={200}
                        >
                            <Grid
                                centered={widget.centered}
                                columns={widget.content.length || 12}
                            >
                                {title &&
                                (comments || widget.content.length) ? (
                                    <Divider />
                                ) : null}
                                {widget.content.length ? (
                                    <Grid.Row>
                                        {widget.content.map(
                                            (element, index) => {
                                                const Widget =
                                                    widgets[element.widget];
                                                return (
                                                    <Grid.Column
                                                        key={index}
                                                        textAlign={widget.align}
                                                        mobile={
                                                            widget.responsive
                                                                .mobile
                                                        }
                                                        tablet={
                                                            widget.responsive
                                                                .tablet
                                                        }
                                                        computer={
                                                            widget.responsive
                                                                .computer
                                                        }
                                                    >
                                                        <Widget
                                                            {...element.params}
                                                            data={data}
                                                        />
                                                    </Grid.Column>
                                                );
                                            }
                                        )}
                                    </Grid.Row>
                                ) : null}
                                {(details && details.length) ? (
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        {" "}
                                        <Grid.Column
                                            mobile={widget.responsive.mobile}
                                            tablet={widget.responsive.tablet}
                                            computer={
                                                widget.responsive.computer
                                            }
                                        >
                                            <Table unstackable>
                                                <Table.Header>
                                                    <Table.Row></Table.Row>
                                                </Table.Header>

                                                <Table.Body
                                                    style={{
                                                        backgroundColor:
                                                            "#f9fafb",
                                                    }}
                                                >
                                                    {details.map(
                                                        (item, index) => {
                                                            return (
                                                                <Table.Row
                                                                    key={index}
                                                                >
                                                                    {Object.keys(
                                                                        item
                                                                    ).map(
                                                                        (
                                                                            element,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <Table.Cell
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {Array.isArray(
                                                                                        item[
                                                                                            element
                                                                                        ]
                                                                                    ) ? (
                                                                                        <List
                                                                                            items={
                                                                                                item[
                                                                                                    element
                                                                                                ]
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        <Fragment>
                                                                                            {
                                                                                                item[
                                                                                                    element
                                                                                                ]
                                                                                            }
                                                                                        </Fragment>
                                                                                    )}
                                                                                </Table.Cell>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Table.Row>
                                                            );
                                                        }
                                                    )}
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                    </Grid.Row>
                                ): null}
                                {comments && (
                                    <Grid.Row centered>
                                        <Grid.Column
                                            textAlign="center"
                                            mobile={14}
                                            tablet={10}
                                            computer={8}
                                        >
                                            {comments}
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            </Grid>
                        </Transition>
                    </Segment>
                )}
            </Fragment>
        );
    }
}

export { WidgetBlock };
