// @flow
import React, { Component } from 'react';
import { Item } from 'semantic-ui-react';
import{ capitalize } from 'tools'; 

import './WidgetItem.scss';

type Props = {
    content: any,
    data: any
}

type State = {};

/**
 * WidgetItem widget
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description Widget with items
 * @return {ReactComponent} WidgetItem
 */
class WidgetItem extends Component<Props,State> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetItem
         * @return { String } JSX 
         */
        const { data } = this.props;
        return (
            <Item.Group unstackable divided relaxed> 
                {data.content.map((element, index) => {  
                    return(
                        <Item key={index} >
                            <Item.Content>
                                <Item.Header className='color-default-light'>{capitalize(element.name)}</Item.Header>
                                <Item.Description>{capitalize(element.description)}</Item.Description>
                            </Item.Content>
                        </Item> 
                    )
                })}
            </Item.Group>
        );
    }
}

export { WidgetItem }