// @flow
import React, { Component, Fragment } from 'react';
import { Redirect }  from "react-router-dom";
import { Authentication } from 'components';

type Props = {
    consumers: any
}

type State = {}

/**
 * Auth
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Auth container
 */

class Auth extends Component<Props, State> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Auth
         * @return { String } JSX 
        */
        const { authenticated } = this.props.consumers.connection
        const { consumers } = this.props
        return (
            <Fragment>
                {authenticated ? (
                    <Redirect to="/"/>
                ) : (
                    <Authentication consumers={consumers}/>
                )}
            </Fragment>
        );
    }
}

export { Auth }