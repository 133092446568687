// @flow
import React, { Component } from 'react';
import { Wrapper } from "components";

import './Helper.scss';

type Props = {}

type State = {};

/**
 * Helper
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description Helper component
 */
class Helper extends Component<Props, State> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Components.Helper
         * @return { String } JSX 
         */
        return (
            <Wrapper name="Helper">
                <Wrapper name="dots">
                    <Wrapper name="dot pos-18 bg-green">
                        <Wrapper as='span' name="info info-bottom color-green">Aucune garantie minimum</Wrapper>
                    </Wrapper>
                    <Wrapper name="dot pos-38 bg-yellow">
                        <Wrapper as='span' name="info info-top color-yellow">Garanties <br/> minimum</Wrapper>
                    </Wrapper>
                    <Wrapper name="dot pos-58 bg-orange">
                        <Wrapper as='span' name="info info-bottom color-orange">Garanties  <br/>minimum & assureurs</Wrapper>
                    </Wrapper>
                    <Wrapper name="dot pos-78 bg-red">
                        <Wrapper as='span' name="info info-top color-red">Garanties  <br/>compliquées</Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper name="bar"></Wrapper>
            </Wrapper>
        );
    }
}

export { Helper };