// @flow
import React, { Component } from "react";
import { Loading } from "components";
import { Grid, Segment, Message, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

type Props = {
    match: any,
    history: any,
    location: any,
    consumers: any,
};

type State = {
    loading: boolean,
};

/**
 * Authentication Container
 * @author Romain rsa@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Authentication container
 */

class WithRouterAuthentication extends Component<Props, State> {
    constructor(props: Props) {
        /**
         * @constructor
         */
        super(props);
        this.state = {
            loading: false,
        };
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Authenticate
         * @method componentDidMount
         * @return { Void }
         */
        try {
            const { location, consumers } = this.props;

            const params = new URLSearchParams(location.search);
            const token = params.get("token");
            const ccn = params.get("ccn");

            if (token) {
                await this.setState({
                    loading: true,
                });
                await consumers.connection.login(token);
                await this.setState({
                    loading: false,
                });
                if (ccn) {
                    this.props.history.replace(`/ccn/${ccn}`);
                }
            }
        } catch (error) {
            await this.setState({
                loading: false,
            });
            this.props.history.push("/");
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Activate
         * @return { String } JSX
         */
        const { loading } = this.state;
        return (
            <Grid className="Activate login" centered padded columns={1}>
                <Grid.Column mobile={14} tablet={8} computer={6}>
                    {loading ? (
                        <Loading title="Veuillez patienter" />
                    ) : (
                        <Segment textAlign="center" style={{ margin: "5em 0" }}>
                            <Message
                                icon="info circle"
                                size="big"
                                header="Votre session a expiré "
                                content={
                                    <a
                                        className="link medium center"
                                        href="https://www.fasst.io"
                                    >
                                        <Button
                                            basic
                                            size="small"
                                            style={{ margin: "1em 0 0 0" }}
                                        >
                                            Veuillez vous reconnecter
                                        </Button>
                                    </a>
                                }
                            />
                        </Segment>
                    )}
                </Grid.Column>
            </Grid>
        );
    }
}

const Authentication = withRouter(WithRouterAuthentication);
export { Authentication };
