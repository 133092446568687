// @flow
import React, { Component } from "react";
import { List } from "semantic-ui-react";
import { resolvePath, stringConcat } from "tools";
import { icons } from "icons";
import "./WidgetList.scss";

type Props = {
    styles: any,
    content: any,
    data: any,
};

type State = {};

/**
 * WidgetList widget
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description Widget order list content
 * @return {ReactComponent} WidgetList
 */
class WidgetList extends Component<Props, State> {
    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetList
         * @return { String } JSX
         */
        const { content, styles, data } = this.props;
        const css = styles !== undefined ? styles : {};
        const css_content = css.content !== undefined ? css.content : {};
        const css_header = css.header !== undefined ? css.header : {};
        const css_description =
            css.description !== undefined ? css.description : {};

        return (
            <List
                className="Widget-list"
                divided
                size="huge"
                verticalAlign="middle"
            >
                {content.map((element, index) => {
                    const Icon = icons[element.icon] ? icons[element.icon] : icons['iconBed'];
                    const header = element.header
                        ? resolvePath(data, element.header)
                        : "";
                    const description = element.description
                        ? stringConcat(data, element.description)
                        : "";
                    return (
                        <List.Item key={index}>
                            <span style={{  width: "5rem", display: "inline-block", verticalAlign: "middle"}}>
                                <Icon />
                            </span>
                            <List.Content style={{ ...css_content, display: "inline-block" }}>
                                <List.Header style={{ ...css_header }} as="h3">
                                    {header}
                                </List.Header>
                                <List.Description
                                    style={{ ...css_description }}
                                >
                                    {description}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
        );
    }
}

export { WidgetList };
