// @flow
import React, { Component } from 'react';

type Props = {
    name: string,
    as: string,
    style: any,
    children: any
}

type State = {};

 /**
 * Wrapper
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description Wrapper component
 */
class Wrapper extends Component<Props, State> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Components.Wrapper
         * @return { String } JSX 
         */
        const Tag = this.props.as || `div`;
        return (
        <Tag className={this.props.name} style={this.props.style}>
            {this.props.children}
        </Tag>)
    }
}
export { Wrapper };