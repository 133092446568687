// @flow
import React from "react";
import { Auth } from "services/authService";
import { LocalStorage, SessionStorage } from "services/storageService";
import jwt from "jsonwebtoken";

const ConnectionContext = React.createContext<any>();

type Props = {
    children: any,
};

type State = {
    authenticated: boolean,
    colors: string,
    header: boolean,
    footer: boolean,
    authorizedParts: Array<string>,
    expire: any,
    issued: any,
};

/**
 * ConnectionProvider
 * @author Tomasz tpr@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description Connection provider
 */
class ConnectionProvider extends React.Component<Props, State> {
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props);
        this.state = {
            authenticated: false,
            colors: "",
            header: false,
            footer: false,
            authorizedParts: [],
            expire: null,
            issued: null,
        };
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @method componentDidMount
         * @memberof Providers.ConnectionProvider
         * @return { Void }
         */
        await SessionStorage.setItem(
            "loaded_at",
            Math.floor(new Date().getTime() / 1000)
        );
        const token = await LocalStorage.getItem("token");
        if (token) {
            const decoded = jwt.decode(token);
            await this.setState({
                authenticated: true,
                authorizedParts: decoded.authorizedParts,
                expire: decoded.exp,
                issued: decoded.iat,
            });
        }
    }

    login = async (token: string) => {
        /**
         * @instance
         * @async
         * @method login
         * @memberof Providers.ConnectionProvider
         * @param { String } token JWT Token
         * @return { Boolean } Authenticated state
         */
        try {
            const response = await Auth.getToken(token);
            const payload = await response.json();
            if (response.status !== 200) {
                const error = { status: response.status };
                throw error;
            }
            const decoded = jwt.decode(payload.token);
            const loaded_at = await SessionStorage.getItem("loaded_at");
            const update_time = Math.floor(
                new Date().setHours(1, 0, 0, 0) / 1000
            );
            if (loaded_at && Number(loaded_at) < update_time) {
                window.location.reload(true);
                await SessionStorage.setItem(
                    "loaded_at",
                    Math.floor(new Date().getTime() / 1000)
                );
            }
            if ( payload.token) {
            
                await LocalStorage.setItems({
                    issuer: payload.issuer,
                    token: payload.token,
                    colors: payload.colors,
                    header: payload.header,
                    footer: payload.footer,
                    authorizedParts: payload.authorizedParts,
                    logo: payload.logo,
                });
                await this.setState({
                    authenticated: true,
                    authorizedParts: payload.authorizedParts,
                    header: payload.header,
                    footer: payload.footer,
                    expire: decoded.exp,
                    issued: decoded.iat,
                });
                return this.state.authenticated;
            } else {
                const error = { status: 403 };
                throw error;
            }
        } catch (error) {
            throw error;
        }
    };

    logout = async () => {
        /**
         * @instance
         * @async
         * @method logout
         * @memberof Providers.ConnectionProvider
         * @return { Void }
         */
        await LocalStorage.clear();
        await this.setState({ authenticated: false });
    };

    expiredToken = async () => {
        /**
         * @instance
         * @async
         * @method expiredToken
         * @memberof Providers.ConnectionProvider
         * @return { Boolean } Token status
         */
        const token = await LocalStorage.getItem("token");
        if (!token || this.state.expire < Math.floor(Date.now() / 1000)) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.ConnectionProvider
         * @return { String } JSX
         */
        return (
            <ConnectionContext.Provider
                value={{
                    ...this.state,
                    login: this.login,
                    logout: this.logout,
                    expiredToken: this.expiredToken,
                }}
            >
                {this.props.children}
            </ConnectionContext.Provider>
        );
    }
}

const ConnectionConsumer = ConnectionContext.Consumer;

export { ConnectionProvider, ConnectionConsumer };
