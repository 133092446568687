// @flow
import { LocalStorage } from './storageService';
import { checkStatus, checkError } from 'tools';

/**
 * I8S service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description I8S service
 */
class I8S {

    static url:any = process.env.REACT_APP_I8S_API;

    static entrypoints:any = {
        i8s : 'apps/ccn/i8s'
    }

    static getEtablissement = async (siren:number) => {
        /**
         * Get Etablissement
         *
         * @async
         * @method getEtablissement
         * @memberof Services.I8S
         * @param siret Siret to search
         * @return { Object } Etablissement
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.i8s}/etablissement/${siren}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }

    static getEntreprise = async (siren:string) => {
        /**
         * Get Entreprise
         *
         * @async
         * @method getEntreprise
         * @memberof Services.I8S
         * @param siren SIREN to get
         * @return { Object } Entreprise
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.i8s}/entreprise/${siren}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }

    static getEntreprises = async (sirens:Array<string>) => {
        /**
         * Get Entreprises
         *
         * @async
         * @method getEntreprises
         * @memberof Services.I8S
         * @param siren SIRENS to get
         * @return { Object } Entreprises
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.i8s}/entreprise`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({ sirens: sirens}),
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }

    static getEtablissementsByIdcc = async (idcc:string, range:number = 1, coords:any, radius:number = 500) => {
        /**
         * Get Etablissement by Idcc
         *
         * @async
         * @method getEtablissementsByIdcc
         * @memberof Services.I8S
         * @param idcc IDCC code to get
         * @param page page number
         * @param coords coordinates of user
         * @param radius radius to search
         * @return { Array } Etablissements
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.i8s}/etablissements/idcc`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    idcc:idcc,
                    page:range,
                    longitude:coords.longitude,
                    latitude:coords.latitude,
                    radius:radius
                }),
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }

    static getEtablissementsByApe = async (idcc:string, range:number = 1, coords:any, radius:number = 2000) => {
        /**
         * Get Etablissements by Ape
         *
         * @async
         * @method getEtablissementsByApe
         * @memberof Services.I8S
         * @param idcc IDCC code to get
         * @param page page number
         * @param coords coordinates of user
         * @param radius radius to search
         * @return { Array } Etablissements
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const { ape } = await LocalStorage.getItemAsJSON('ccn') ;
            const response = await fetch(`${this.url}/${this.entrypoints.i8s}/etablissements/ape`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    apes:ape,
                    page:range,
                    longitude:coords.longitude,
                    latitude:coords.latitude,
                    radius:radius
                }),
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }

    static getKeyFigures = async (sirens:any) => {
        /**
         *  Get Key Figures
         *
         * @async
         * @method getKeyFigures
         * @memberof Services.I8S
         * @param siren Siren
         * @return { Array } Chiffres clés
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.i8s}/chiffresCles`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({sirens :sirens}),
                mode: 'cors'
            });
            return response;
        } catch(error) {
            throw checkError(error);
        }
    }
}
export  { I8S };