// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu, Icon } from "semantic-ui-react";

import "./Footer.scss";

/**
 * Footer
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description Footer component
 */
class Footer extends Component<{}> {
    render() {
        /**
         * @instance
         * @method render
         * @memberof Components.Footer
         * @return { String } JSX
         */

        return (
            <Menu
                fixed="bottom"
                size="large"
                className="Footer"
                secondary
                fluid
                widths={4}
            >
                <Menu.Item>
                    <Link to="/">
                        <Icon name="home" size="large" />
                    </Link>
                </Menu.Item>

                <Menu.Item>
                    <Link to="/articles">
                        <Icon name="book" size="large" />
                    </Link>
                </Menu.Item>
            </Menu>
        );
    }
}

export { Footer };
