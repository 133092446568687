// @flow
import React, { Component, Fragment } from "react";
import { Grid } from "semantic-ui-react";
import { Wrapper, Loading } from "components";
import { stringConcat } from "tools";
import { icons } from "icons";

import "./WidgetGrid.scss";

type Props = {
    responsive: any,
    vertical: boolean,
    styles: any,
    image: any,
    content: any,
    content: any,
    data: any,
};

type State = {};

/**
 * WidgetGrid widget
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description WidgetGrid
 * @return {ReactComponent} WidgetGrid
 */
class WidgetGrid extends Component<Props, State> {
    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetGrid
         * @return { String } JSX
         */
        const {
            responsive,
            content,
            data,
            vertical,
            styles,
        } = this.props;
        const align = vertical ? "  " : "center";
        const display = vertical ? "block" : "inline-block";
        const css = styles !== undefined ? styles : {};
        const css_title = css.title !== undefined ? css.title : {};
        const css_header = css.header !== undefined ? css.header : {};
        const css_paragraph = css.paragraph !== undefined ? css.paragraph : {};
   
        return (
            <Grid>
                {data && content ? (
                    <Grid.Row className="Widget-grid">
                        {content.map((element, index) => {
                            const header = element.header
                                ? stringConcat(data, element.header)
                                : "";
                            const title = element.title
                                ? stringConcat(data, element.title)
                                : "";
                            const paragraph = element.paragraph
                                ? stringConcat(data, element.paragraph)
                                : "";

                            const Icon = icons[element.icon]
                                ? icons[element.icon]
                                : icons["iconBed"];

                            return (
                                <Grid.Column
                                    textAlign="center"
                                    key={index}
                                    mobile={responsive.mobile}
                                    tablet={responsive.tablet}
                                    computer={responsive.computer}
                                >
                                    <Fragment>
                                        <span
                                            style={{
                                                width: "5rem",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                            }}
                                        >
                                            <Icon />
                                        </span>
                                        <Wrapper
                                            name={"title " + align}
                                            style={{
                                                ...css_title,
                                                ...{ display: display },
                                            }}
                                        >
                                            {" "}
                                            {title}
                                        </Wrapper>
                                        <Wrapper
                                            name={"header " + align}
                                            style={{
                                                ...css_header,
                                                ...{ display: display },
                                            }}
                                        >
                                            {header}
                                        </Wrapper>
                                        <Wrapper
                                            name={"description " + align}
                                            style={{
                                                ...css_paragraph,
                                                ...{ display: display },
                                            }}
                                        >
                                            {paragraph}
                                        </Wrapper>
                                    </Fragment>
                                </Grid.Column>
                            );
                        })}
                    </Grid.Row>
                ) : (
                    <Loading title="Chargement" />
                )}
            </Grid>
        );
    }
}

export { WidgetGrid };
